export const VEHICLE_DATA = {
    data: [
        {
            vehicleType: "Car",
            icon: "ic_car.svg",
            manufacturers: [
                {
                    name: "Acura",
                    models: [
                        {
                            name: "ILX"
                        },
                        {
                            name: "MDX"
                        },
                        {
                            name: "RDX"
                        },
                        {
                            name: "TLX"
                        }
                    ]
                },
                {
                    name: "Audi",
                    models: [
                        {
                            name: "A3"
                        },
                        {
                            name: "A4"
                        },
                        {
                            name: "Q5"
                        },
                        {
                            name: "Q7"
                        }
                    ]
                },
                {
                    name: "BMW",
                    models: [
                        {
                            name: "3 Series"
                        },
                        {
                            name: "5 Series"
                        },
                        {
                            name: "X5"
                        },
                        {
                            name: "X7"
                        }
                    ]
                },
                {
                    name: "Buick",
                    models: [
                        {
                            name: "Enclave"
                        },
                        {
                            name: "Encore"
                        },
                        {
                            name: "Envision"
                        }
                    ]
                },
                {
                    name: "Cadillac",
                    models: [
                        {
                            name: "CT5"
                        },
                        {
                            name: "Escalade"
                        },
                        {
                            name: "XT4"
                        },
                        {
                            name: "XT5"
                        }
                    ]
                },
                {
                    name: "Chevrolet",
                    models: [
                        {
                            name: "Bolt EV"
                        },
                        {
                            name: "Equinox"
                        },
                        {
                            name: "Silverado"
                        },
                        {
                            name: "Tahoe"
                        }
                    ]
                },
                {
                    name: "Chrysler",
                    models: [
                        {
                            name: "300"
                        },
                        {
                            name: "Pacifica"
                        }
                    ]
                },
                {
                    name: "Dodge",
                    models: [
                        {
                            name: "Charger"
                        },
                        {
                            name: "Durango"
                        },
                        {
                            name: "Challenger"
                        }
                    ]
                },
                {
                    name: "Ford",
                    models: [
                        {
                            name: "F-150"
                        },
                        {
                            name: "Escape"
                        },
                        {
                            name: "Mustang"
                        },
                        {
                            name: "Explorer"
                        }
                    ]
                },
                {
                    name: "GMC",
                    models: [
                        {
                            name: "Acadia"
                        },
                        {
                            name: "Sierra"
                        },
                        {
                            name: "Terrain"
                        },
                        {
                            name: "Yukon"
                        }
                    ]
                },
                {
                    name: "Genesis",
                    models: [
                        {
                            name: "G70"
                        },
                        {
                            name: "G80"
                        },
                        {
                            name: "GV70"
                        },
                        {
                            name: "GV80"
                        }
                    ]
                },
                {
                    name: "Honda",
                    models: [
                        {
                            name: "Accord"
                        },
                        {
                            name: "Civic"
                        },
                        {
                            name: "CR-V"
                        },
                        {
                            name: "Pilot"
                        }
                    ]
                },
                {
                    name: "Hyundai",
                    models: [
                        {
                            name: "Elantra"
                        },
                        {
                            name: "Kona"
                        },
                        {
                            name: "Palisade"
                        },
                        {
                            name: "Tucson"
                        }
                    ]
                },
                {
                    name: "Infiniti",
                    models: [
                        {
                            name: "Q50"
                        },
                        {
                            name: "QX50"
                        },
                        {
                            name: "QX60"
                        }
                    ]
                },
                {
                    name: "Jeep",
                    models: [
                        {
                            name: "Cherokee"
                        },
                        {
                            name: "Compass"
                        },
                        {
                            name: "Grand Cherokee"
                        },
                        {
                            name: "Wrangler"
                        }
                    ]
                },
                {
                    name: "Kia",
                    models: [
                        {
                            name: "Forte"
                        },
                        {
                            name: "Sorento"
                        },
                        {
                            name: "Sportage"
                        },
                        {
                            name: "Telluride"
                        }
                    ]
                },
                {
                    name: "Land Rover",
                    models: [
                        {
                            name: "Defender"
                        },
                        {
                            name: "Discovery"
                        },
                        {
                            name: "Range Rover"
                        }
                    ]
                },
                {
                    name: "Lexus",
                    models: [
                        {
                            name: "ES"
                        },
                        {
                            name: "GX"
                        },
                        {
                            name: "NX"
                        },
                        {
                            name: "RX"
                        }
                    ]
                },
                {
                    name: "Lincoln",
                    models: [
                        {
                            name: "Aviator"
                        },
                        {
                            name: "Corsair"
                        },
                        {
                            name: "Nautilus"
                        },
                        {
                            name: "Navigator"
                        }
                    ]
                },
                {
                    name: "Mazda",
                    models: [
                        {
                            name: "CX-5"
                        },
                        {
                            name: "CX-9"
                        },
                        {
                            name: "Mazda3"
                        },
                        {
                            name: "Mazda6"
                        }
                    ]
                },
                {
                    name: "Mercedes-Benz",
                    models: [
                        {
                            name: "C-Class"
                        },
                        {
                            name: "E-Class"
                        },
                        {
                            name: "GLA"
                        },
                        {
                            name: "GLE"
                        }
                    ]
                },
                {
                    name: "Mitsubishi",
                    models: [
                        {
                            name: "Eclipse Cross"
                        },
                        {
                            name: "Outlander"
                        },
                        {
                            name: "Mirage"
                        }
                    ]
                },
                {
                    name: "Nissan",
                    models: [
                        {
                            name: "Altima"
                        },
                        {
                            name: "Rogue"
                        },
                        {
                            name: "Sentra"
                        },
                        {
                            name: "Titan"
                        }
                    ]
                },
                {
                    name: "Porsche",
                    models: [
                        {
                            name: "911"
                        },
                        {
                            name: "Cayenne"
                        },
                        {
                            name: "Macan"
                        },
                        {
                            name: "Taycan"
                        }
                    ]
                },
                {
                    name: "Ram",
                    models: [
                        {
                            name: "1500"
                        },
                        {
                            name: "2500"
                        },
                        {
                            name: "3500"
                        }
                    ]
                },
                {
                    name: "Subaru",
                    models: [
                        {
                            name: "Forester"
                        },
                        {
                            name: "Outback"
                        },
                        {
                            name: "Impreza"
                        },
                        {
                            name: "Ascent"
                        }
                    ]
                },
                {
                    name: "Tesla",
                    models: [
                        {
                            name: "Model 3"
                        },
                        {
                            name: "Model S"
                        },
                        {
                            name: "Model X"
                        },
                        {
                            name: "Model Y"
                        }
                    ]
                },
                {
                    name: "Toyota",
                    models: [
                        {
                            name: "Camry"
                        },
                        {
                            name: "Corolla"
                        },
                        {
                            name: "Highlander"
                        },
                        {
                            name: "RAV4"
                        }
                    ]
                },
                {
                    name: "Volkswagen",
                    models: [
                        {
                            name: "Atlas"
                        },
                        {
                            name: "Golf"
                        },
                        {
                            name: "Jetta"
                        },
                        {
                            name: "Tiguan"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Truck",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Chevrolet",
                    models: [
                        {
                            name: "Silverado 1500"
                        },
                        {
                            name: "Colorado"
                        },
                        {
                            name: "Silverado 2500HD"
                        }
                    ]
                },
                {
                    name: "Dodge",
                    models: [
                        {
                            name: "Ram 1500"
                        },
                        {
                            name: "Ram 2500"
                        }
                    ]
                },
                {
                    name: "Ford",
                    models: [
                        {
                            name: "F-150"
                        },
                        {
                            name: "F-250 Super Duty"
                        },
                        {
                            name: "Ranger"
                        }
                    ]
                },
                {
                    name: "GMC",
                    models: [
                        {
                            name: "Sierra 1500"
                        },
                        {
                            name: "Canyon"
                        },
                        {
                            name: "Sierra 2500HD"
                        }
                    ]
                },
                {
                    name: "Honda",
                    models: [
                        {
                            name: "Ridgeline"
                        }
                    ]
                },
                {
                    name: "Jeep",
                    models: [
                        {
                            name: "Gladiator"
                        }
                    ]
                },
                {
                    name: "Nissan",
                    models: [
                        {
                            name: "Frontier"
                        },
                        {
                            name: "Titan"
                        }
                    ]
                },
                {
                    name: "Ram",
                    models: [
                        {
                            name: "1500"
                        },
                        {
                            name: "2500"
                        },
                        {
                            name: "3500"
                        }
                    ]
                },
                {
                    name: "Toyota",
                    models: [
                        {
                            name: "Tacoma"
                        },
                        {
                            name: "Tundra"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Motorcycle",
            icon: "ic_motorbike.svg",
            manufacturers: [
                {
                    name: "Harley-Davidson",
                    models: [
                        {
                            name: "Street Glide"
                        },
                        {
                            name: "Sportster S"
                        },
                        {
                            name: "Fat Boy"
                        },
                        {
                            name: "Pan America"
                        }
                    ]
                },
                {
                    name: "Kawasaki",
                    models: [
                        {
                            name: "Ninja ZX-10R"
                        },
                        {
                            name: "Z650"
                        },
                        {
                            name: "Versys 650"
                        },
                        {
                            name: "Vulcan S"
                        }
                    ]
                },
                {
                    name: "Ducati",
                    models: [
                        {
                            name: "Panigale V4"
                        },
                        {
                            name: "Monster"
                        },
                        {
                            name: "Multistrada V4"
                        },
                        {
                            name: "Scrambler"
                        }
                    ]
                },
                {
                    name: "Triumph Motorcycles Ltd",
                    models: [
                        {
                            name: "Bonneville T120"
                        },
                        {
                            name: "Street Triple"
                        },
                        {
                            name: "Tiger 900"
                        },
                        {
                            name: "Rocket 3"
                        }
                    ]
                },
                {
                    name: "Yamaha Motor Company",
                    models: [
                        {
                            name: "YZF-R1"
                        },
                        {
                            name: "MT-07"
                        },
                        {
                            name: "Tracer 9 GT"
                        },
                        {
                            name: "XSR700"
                        }
                    ]
                },
                {
                    name: "Honda",
                    models: [
                        {
                            name: "CBR1000RR"
                        },
                        {
                            name: "CRF450R"
                        },
                        {
                            name: "Rebel 500"
                        },
                        {
                            name: "Africa Twin"
                        }
                    ]
                },
                {
                    name: "Suzuki",
                    models: [
                        {
                            name: "Hayabusa"
                        },
                        {
                            name: "GSX-R1000"
                        },
                        {
                            name: "V-Strom 650"
                        },
                        {
                            name: "Boulevard M109R"
                        }
                    ]
                },
                {
                    name: "KTM",
                    models: [
                        {
                            name: "Duke 390"
                        },
                        {
                            name: "1290 Super Adventure"
                        },
                        {
                            name: "RC 390"
                        },
                        {
                            name: "450 SX-F"
                        }
                    ]
                },
                {
                    name: "Aprilia",
                    models: [
                        {
                            name: "RSV4"
                        },
                        {
                            name: "Tuono 660"
                        },
                        {
                            name: "RS 660"
                        },
                        {
                            name: "Tuareg 660"
                        }
                    ]
                },
                {
                    name: "BMW",
                    models: [
                        {
                            name: "S 1000 RR"
                        },
                        {
                            name: "R 1250 GS"
                        },
                        {
                            name: "F 900 R"
                        },
                        {
                            name: "G 310 R"
                        }
                    ]
                },
                {
                    name: "Indian Motorcycle",
                    models: [
                        {
                            name: "Scout"
                        },
                        {
                            name: "Chief"
                        },
                        {
                            name: "Chieftain"
                        },
                        {
                            name: "FTR"
                        }
                    ]
                },
                {
                    name: "Royal Enfield",
                    models: [
                        {
                            name: "Interceptor 650"
                        },
                        {
                            name: "Classic 350"
                        },
                        {
                            name: "Himalayan"
                        },
                        {
                            name: "Meteor 350"
                        }
                    ]
                },
                {
                    name: "Zero Motorcycles",
                    models: [
                        {
                            name: "SR/F"
                        },
                        {
                            name: "FX"
                        },
                        {
                            name: "DSR"
                        },
                        {
                            name: "SR/S"
                        }
                    ]
                },
                {
                    name: "Indian",
                    models: [
                        {
                            name: "Scout Bobber"
                        },
                        {
                            name: "Chief Dark Horse"
                        },
                        {
                            name: "Roadmaster"
                        }
                    ]
                },
                {
                    name: "Moto Guzzi",
                    models: [
                        {
                            name: "V85 TT"
                        },
                        {
                            name: "V7 Stone"
                        },
                        {
                            name: "V9 Bobber"
                        },
                        {
                            name: "MGX-21"
                        }
                    ]
                },
                {
                    name: "Buell Motorcycle Company",
                    models: [
                        {
                            name: "Hammerhead 1190"
                        },
                        {
                            name: "1190SX"
                        },
                        {
                            name: "1190RX"
                        }
                    ]
                },
                {
                    name: "Absolom Motorcycles",
                    models: []
                },
                {
                    name: "ARCH Motorcycle",
                    models: [
                        {
                            name: "KRGT-1"
                        },
                        {
                            name: "1s"
                        }
                    ]
                },
                {
                    name: "Bajaj Auto",
                    models: [
                        {
                            name: "Pulsar 220F"
                        },
                        {
                            name: "Dominar 400"
                        },
                        {
                            name: "Avenger Cruise 220"
                        },
                        {
                            name: "CT100"
                        }
                    ]
                },
                {
                    name: "Boss Hoss Cycles",
                    models: [
                        {
                            name: "BHC-3 LS3"
                        },
                        {
                            name: "Gangsta"
                        }
                    ]
                },
                {
                    name: "Can-Am motorcycles",
                    models: [
                        {
                            name: "Spyder F3"
                        },
                        {
                            name: "Ryker"
                        }
                    ]
                },
                {
                    name: "Cleveland CycleWerks",
                    models: [
                        {
                            name: "Heist"
                        },
                        {
                            name: "Ace"
                        },
                        {
                            name: "Misfit"
                        }
                    ]
                },
                {
                    name: "MV Agusta",
                    models: [
                        {
                            name: "Brutale 1000 RR"
                        },
                        {
                            name: "F3 800"
                        },
                        {
                            name: "Dragster 800"
                        },
                        {
                            name: "Superveloce"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Motorhome",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Airstream",
                    models: [
                        {
                            name: "Interstate 24GT"
                        },
                        {
                            name: "Atlas"
                        },
                        {
                            name: "Classic"
                        }
                    ]
                },
                {
                    name: "Coachmen Recreational Vehicle Company, LLC",
                    models: [
                        {
                            name: "Freelander"
                        },
                        {
                            name: "Leprechaun"
                        },
                        {
                            name: "Cross Trek"
                        }
                    ]
                },
                {
                    name: "Jayco, Inc.",
                    models: [
                        {
                            name: "Greyhawk"
                        },
                        {
                            name: "Alante"
                        },
                        {
                            name: "Redhawk"
                        }
                    ]
                },
                {
                    name: "Entegra Coach, Inc.",
                    models: [
                        {
                            name: "Cornerstone"
                        },
                        {
                            name: "Anthem"
                        },
                        {
                            name: "Accolade"
                        }
                    ]
                },
                {
                    name: "Newmar Corporation",
                    models: [
                        {
                            name: "Dutch Star"
                        },
                        {
                            name: "Ventana"
                        },
                        {
                            name: "King Aire"
                        }
                    ]
                },
                {
                    name: "Winnebago",
                    models: [
                        {
                            name: "Travato"
                        },
                        {
                            name: "Revel"
                        },
                        {
                            name: "Solis"
                        }
                    ]
                },
                {
                    name: "Forest River",
                    models: [
                        {
                            name: "Forester"
                        },
                        {
                            name: "Sunseeker"
                        },
                        {
                            name: "Georgetown"
                        }
                    ]
                },
                {
                    name: "Thor Industries",
                    models: [
                        {
                            name: "ACE"
                        },
                        {
                            name: "Challenger"
                        },
                        {
                            name: "Quantum"
                        }
                    ]
                },
                {
                    name: "American Coach",
                    models: [
                        {
                            name: "American Dream"
                        },
                        {
                            name: "American Tradition"
                        },
                        {
                            name: "Patriot"
                        }
                    ]
                },
                {
                    name: "Grand Design RV",
                    models: [
                        {
                            name: "Momentum"
                        },
                        {
                            name: "Solitude"
                        },
                        {
                            name: "Reflection"
                        }
                    ]
                },
                {
                    name: "DS Corporation",
                    models: []
                },
                {
                    name: "Dutchmen",
                    models: [
                        {
                            name: "Astoria"
                        },
                        {
                            name: "Kodiak"
                        },
                        {
                            name: "Voltage"
                        }
                    ]
                },
                {
                    name: "Fleetwood Enterprises",
                    models: [
                        {
                            name: "Bounder"
                        },
                        {
                            name: "Southwind"
                        },
                        {
                            name: "Pace Arrow"
                        }
                    ]
                },
                {
                    name: "Heartland Recreational Vehicles",
                    models: [
                        {
                            name: "Cyclone"
                        },
                        {
                            name: "Bighorn"
                        },
                        {
                            name: "Road Warrior"
                        }
                    ]
                },
                {
                    name: "Keystone RV Co",
                    models: [
                        {
                            name: "Montana"
                        },
                        {
                            name: "Cougar"
                        },
                        {
                            name: "Raptor"
                        }
                    ]
                },
                {
                    name: "Nexus RV LLC",
                    models: [
                        {
                            name: "Wraith"
                        },
                        {
                            name: "Viper"
                        },
                        {
                            name: "Ghost"
                        }
                    ]
                },
                {
                    name: "Bigfoot Industries, Inc.",
                    models: [
                        {
                            name: "2500 Series"
                        },
                        {
                            name: "3000 Series"
                        }
                    ]
                },
                {
                    name: "Chinook RV",
                    models: [
                        {
                            name: "Bayside"
                        },
                        {
                            name: "Concourse"
                        },
                        {
                            name: "Maverick"
                        }
                    ]
                },
                {
                    name: "Cruiser RV, LLC",
                    models: [
                        {
                            name: "Radiance"
                        },
                        {
                            name: "Embrace"
                        },
                        {
                            name: "Stryker"
                        }
                    ]
                },
                {
                    name: "Holiday Rambler",
                    models: [
                        {
                            name: "Admiral"
                        },
                        {
                            name: "Vacationer"
                        },
                        {
                            name: "Navigator"
                        }
                    ]
                },
                {
                    name: "Allen Camper Manufacturing Co",
                    models: []
                },
                {
                    name: "Alliance RV",
                    models: [
                        {
                            name: "Paradigm"
                        },
                        {
                            name: "Valor"
                        },
                        {
                            name: "Avenue"
                        }
                    ]
                },
                {
                    name: "Bowlus",
                    models: [
                        {
                            name: "Terra Firma"
                        },
                        {
                            name: "Endless Highways"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Semi Truck",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Freightliner Trucks",
                    models: [
                        {
                            name: "Cascadia"
                        },
                        {
                            name: "M2 106"
                        },
                        {
                            name: "122SD"
                        }
                    ]
                },
                {
                    name: "Volvo",
                    models: [
                        {
                            name: "VNL 860"
                        },
                        {
                            name: "VNR 640"
                        },
                        {
                            name: "VHD 300"
                        }
                    ]
                },
                {
                    name: "Kenworth",
                    models: [
                        {
                            name: "T680"
                        },
                        {
                            name: "T880"
                        },
                        {
                            name: "W990"
                        }
                    ]
                },
                {
                    name: "Peterbilt",
                    models: [
                        {
                            name: "579"
                        },
                        {
                            name: "389"
                        },
                        {
                            name: "567"
                        }
                    ]
                },
                {
                    name: "Mack",
                    models: [
                        {
                            name: "Anthem"
                        },
                        {
                            name: "Pinnacle"
                        },
                        {
                            name: "Granite"
                        }
                    ]
                },
                {
                    name: "Western Star",
                    models: [
                        {
                            name: "49X"
                        },
                        {
                            name: "47X"
                        },
                        {
                            name: "57X"
                        }
                    ]
                },
                {
                    name: "International Trucks",
                    models: [
                        {
                            name: "LT Series"
                        },
                        {
                            name: "RH Series"
                        },
                        {
                            name: "HX Series"
                        }
                    ]
                },
                {
                    name: "Ford",
                    models: [
                        {
                            name: "F-750"
                        },
                        {
                            name: "L9000"
                        },
                        {
                            name: "CLT9000"
                        }
                    ]
                },
                {
                    name: "Navistar International",
                    models: [
                        {
                            name: "ProStar"
                        },
                        {
                            name: "LoneStar"
                        },
                        {
                            name: "Durastar"
                        }
                    ]
                },
                {
                    name: "MAN",
                    models: [
                        {
                            name: "TGX"
                        },
                        {
                            name: "TGS"
                        },
                        {
                            name: "TGM"
                        }
                    ]
                },
                {
                    name: "PACCAR",
                    models: [
                        {
                            name: "MX-13"
                        },
                        {
                            name: "PX-7"
                        },
                        {
                            name: "PX-9"
                        }
                    ]
                },
                {
                    name: "Scania",
                    models: [
                        {
                            name: "R Series"
                        },
                        {
                            name: "S Series"
                        },
                        {
                            name: "G Series"
                        }
                    ]
                },
                {
                    name: "Autocar Company",
                    models: [
                        {
                            name: "ACX"
                        },
                        {
                            name: "DC-64"
                        },
                        {
                            name: "ACTT"
                        }
                    ]
                },
                {
                    name: "Daimler AG",
                    models: [
                        {
                            name: "Mercedes-Benz Actros"
                        },
                        {
                            name: "Freightliner eCascadia"
                        }
                    ]
                },
                {
                    name: "Hino Motors",
                    models: [
                        {
                            name: "Hino 268"
                        },
                        {
                            name: "Hino XL8"
                        },
                        {
                            name: "Hino 195"
                        }
                    ]
                },
                {
                    name: "Mitsubishi Motors",
                    models: [
                        {
                            name: "Fuso Canter"
                        },
                        {
                            name: "Fuso FE160"
                        }
                    ]
                },
                {
                    name: "International",
                    models: [
                        {
                            name: "LT Series"
                        },
                        {
                            name: "HV Series"
                        },
                        {
                            name: "MV Series"
                        }
                    ]
                },
                {
                    name: "Peterbilt 389",
                    models: [
                        {
                            name: "389X"
                        },
                        {
                            name: "389 Pride & Class"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "UTV",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Polaris",
                    models: [
                        {
                            name: "RZR XP"
                        },
                        {
                            name: "Ranger XP 1000"
                        },
                        {
                            name: "General XP 1000"
                        }
                    ]
                },
                {
                    name: "Yamaha Motor Company",
                    models: [
                        {
                            name: "Wolverine RMAX2 1000"
                        },
                        {
                            name: "YXZ1000R"
                        },
                        {
                            name: "Viking VI"
                        }
                    ]
                },
                {
                    name: "Arctic Cat",
                    models: [
                        {
                            name: "Wildcat XX"
                        },
                        {
                            name: "Prowler Pro"
                        },
                        {
                            name: "Alterra 600"
                        }
                    ]
                },
                {
                    name: "Honda Pioneer",
                    models: [
                        {
                            name: "Pioneer 1000"
                        },
                        {
                            name: "Pioneer 700"
                        },
                        {
                            name: "Pioneer 520"
                        }
                    ]
                },
                {
                    name: "Kawasaki Motors",
                    models: [
                        {
                            name: "Teryx KRX 1000"
                        },
                        {
                            name: "MULE PRO-FXT"
                        },
                        {
                            name: "Teryx4"
                        }
                    ]
                },
                {
                    name: "Honda",
                    models: [
                        {
                            name: "Pioneer 1000"
                        },
                        {
                            name: "Pioneer 500"
                        },
                        {
                            name: "Talos 1000X"
                        }
                    ]
                },
                {
                    name: "RZR",
                    models: [
                        {
                            name: "RZR Pro XP"
                        },
                        {
                            name: "RZR Turbo S"
                        }
                    ]
                },
                {
                    name: "Can-Am motorcycles",
                    models: [
                        {
                            name: "Maverick X3"
                        },
                        {
                            name: "Defender"
                        },
                        {
                            name: "Commander"
                        }
                    ]
                },
                {
                    name: "Kawasaki Teryx",
                    models: [
                        {
                            name: "Teryx4"
                        },
                        {
                            name: "Teryx KRX 1000"
                        }
                    ]
                },
                {
                    name: "Polaris Ranger",
                    models: [
                        {
                            name: "Ranger XP 1000"
                        },
                        {
                            name: "Ranger 570"
                        },
                        {
                            name: "Ranger EV"
                        }
                    ]
                },
                {
                    name: "Yamaha Wolverine",
                    models: [
                        {
                            name: "Wolverine X2"
                        },
                        {
                            name: "Wolverine RMAX4 1000"
                        }
                    ]
                },
                {
                    name: "Assault Industries",
                    models: []
                },
                {
                    name: "Can-am Maverick X3",
                    models: [
                        {
                            name: "Maverick X3 MAX"
                        },
                        {
                            name: "Maverick X3 DS Turbo"
                        }
                    ]
                },
                {
                    name: "Honda Pioneer 500",
                    models: [{}]
                },
                {
                    name: "Pioneer 500",
                    name: "John Deere",
                    models: [
                        {
                            name: "Gator XUV835M"
                        },
                        {
                            name: "Gator HPX615E"
                        },
                        {
                            name: "Gator XUV825M"
                        }
                    ]
                },
                {
                    name: "Kawasaki MULE",
                    models: [
                        {
                            name: "MULE PRO-FXT"
                        },
                        {
                            name: "MULE SX"
                        },
                        {
                            name: "MULE 4010"
                        }
                    ]
                },
                {
                    name: "Kubota",
                    models: [
                        {
                            name: "RTV-X1140"
                        },
                        {
                            name: "RTV-X900"
                        },
                        {
                            name: "RTV500"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Boat",
            icon: "ic_boat.svg",
            manufacturers: [
                {
                    name: "Bayliner",
                    models: [
                        {
                            name: "Element M15"
                        },
                        {
                            name: "VR5 Bowrider"
                        },
                        {
                            name: "Trophy T22CC"
                        }
                    ]
                },
                {
                    name: "Boston Whaler",
                    models: [
                        {
                            name: "Montauk 170"
                        },
                        {
                            name: "Outrage 330"
                        },
                        {
                            name: "Super Sport 160"
                        }
                    ]
                },
                {
                    name: "Cruisers Yachts",
                    models: [
                        {
                            name: "50 Cantius"
                        },
                        {
                            name: "42 GLS"
                        },
                        {
                            name: "38 GLS"
                        }
                    ]
                },
                {
                    name: "Sea Ray",
                    models: [
                        {
                            name: "SLX 400"
                        },
                        {
                            name: "SPX 230"
                        },
                        {
                            name: "Sundancer 370"
                        }
                    ]
                },
                {
                    name: "Baia boats",
                    models: [
                        {
                            name: "Azzurra 63"
                        },
                        {
                            name: "Atlantica 78"
                        },
                        {
                            name: "Italia 70"
                        }
                    ]
                },
                {
                    name: "Beneteau",
                    models: [
                        {
                            name: "Antares 11"
                        },
                        {
                            name: "Flyer 9"
                        },
                        {
                            name: "Oceanis 46.1"
                        }
                    ]
                },
                {
                    name: "Grady White",
                    models: [
                        {
                            name: "Canyon 336"
                        },
                        {
                            name: "Fisherman 216"
                        },
                        {
                            name: "Freedom 285"
                        }
                    ]
                },
                {
                    name: "Leopard Catamarans",
                    models: [
                        {
                            name: "Leopard 50"
                        },
                        {
                            name: "Leopard 42"
                        },
                        {
                            name: "Leopard 53 Powercat"
                        }
                    ]
                },
                {
                    name: "Lund",
                    models: [
                        {
                            name: "Pro-V 1875"
                        },
                        {
                            name: "Impact XS 1850"
                        },
                        {
                            name: "Alaskan 1600"
                        }
                    ]
                },
                {
                    name: "Mako",
                    models: [
                        {
                            name: "Pro Skiff 17 CC"
                        },
                        {
                            name: "19 CPX"
                        },
                        {
                            name: "334 CC"
                        }
                    ]
                },
                {
                    name: "Malibu",
                    models: [
                        {
                            name: "M240"
                        },
                        {
                            name: "23 LSV"
                        },
                        {
                            name: "Wakesetter 21 LX"
                        }
                    ]
                },
                {
                    name: "Spartan boats",
                    models: [
                        {
                            name: "Lynx 17"
                        },
                        {
                            name: "Alaskan 20"
                        },
                        {
                            name: "Kingfisher 18"
                        }
                    ]
                },
                {
                    name: "Qwest Pontoons",
                    models: [
                        {
                            name: "Edge 816"
                        },
                        {
                            name: "LS 822"
                        },
                        {
                            name: "Avanti 824"
                        }
                    ]
                },
                {
                    name: "Azimut",
                    models: [
                        {
                            name: "Grande 35 Metri"
                        },
                        {
                            name: "Flybridge 72"
                        },
                        {
                            name: "S8"
                        }
                    ]
                },
                {
                    name: "Back Cove",
                    models: [
                        {
                            name: "Back Cove 39O"
                        },
                        {
                            name: "Back Cove 34O"
                        },
                        {
                            name: "Back Cove 41"
                        }
                    ]
                },
                {
                    name: "Bennington",
                    models: [
                        {
                            name: "23 SSBX"
                        },
                        {
                            name: "25 RX Sport"
                        },
                        {
                            name: "28 QXFB"
                        }
                    ]
                },
                {
                    name: "Bertram",
                    models: [
                        {
                            name: "Bertram 35"
                        },
                        {
                            name: "Bertram 61"
                        },
                        {
                            name: "Bertram 28 CC"
                        }
                    ]
                },
                {
                    name: "Chris-Craft Corporation",
                    models: [
                        {
                            name: "Launch 28 GT"
                        },
                        {
                            name: "Corsair 34"
                        },
                        {
                            name: "Calypso 30"
                        }
                    ]
                },
                {
                    name: "Everglades",
                    models: [
                        {
                            name: "340DC"
                        },
                        {
                            name: "455CC"
                        },
                        {
                            name: "285CC"
                        }
                    ]
                },
                {
                    name: "Intrepid",
                    models: [
                        {
                            name: "475 Panacea"
                        },
                        {
                            name: "407 Cuddy"
                        },
                        {
                            name: "375 Nomad SE"
                        }
                    ]
                },
                {
                    name: "Leopard",
                    models: [
                        {
                            name: "Leopard 45"
                        },
                        {
                            name: "Leopard 53"
                        }
                    ]
                },
                {
                    name: "Lowe Boats",
                    models: [
                        {
                            name: "Roughneck 1860"
                        },
                        {
                            name: "Skorpion 17"
                        },
                        {
                            name: "SF232"
                        }
                    ]
                },
                {
                    name: "Monterey Boats",
                    models: [
                        {
                            name: "M-65"
                        },
                        {
                            name: "298 Super Sport"
                        },
                        {
                            name: "278SS"
                        }
                    ]
                },
                {
                    name: "Yamaha",
                    models: [
                        {
                            name: "242X E-Series"
                        },
                        {
                            name: "275SD"
                        },
                        {
                            name: "190 FSH Sport"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Flatbed Trailer",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Utility Trailer Manufacturing Company",
                    models: [
                        {
                            name: "4000A"
                        },
                        {
                            name: "4000AE Drop Deck"
                        },
                        {
                            name: "Tautliner"
                        }
                    ]
                },
                {
                    name: "Great Dane Trailers",
                    models: [
                        {
                            name: "Freedom LT"
                        },
                        {
                            name: "Freedom SE"
                        },
                        {
                            name: "Freedom XP"
                        }
                    ]
                },
                {
                    name: "Fontaine Trailer",
                    models: [
                        {
                            name: "Infinity"
                        },
                        {
                            name: "Revolution"
                        },
                        {
                            name: "Velocity"
                        }
                    ]
                },
                {
                    name: "Transcraft Corporation",
                    models: [
                        {
                            name: "Eagle II"
                        },
                        {
                            name: "Steel Drop Deck"
                        },
                        {
                            name: "Aluminum Combo"
                        }
                    ]
                },
                {
                    name: "Wabash National Corporation",
                    models: [
                        {
                            name: "DuraPlate Flatbed"
                        },
                        {
                            name: "Transcraft Eagle"
                        },
                        {
                            name: "Benson Aluminum"
                        }
                    ]
                },
                {
                    name: "Reitnouer Trailers",
                    models: [
                        {
                            name: "Big Bubba"
                        },
                        {
                            name: "MaxMiser"
                        },
                        {
                            name: "MultiMax"
                        }
                    ]
                },
                {
                    name: "Benson Trailers",
                    models: [
                        {
                            name: "Benson 524"
                        },
                        {
                            name: "Benson 421"
                        },
                        {
                            name: "Benson 720"
                        }
                    ]
                },
                {
                    name: "East Manufacturing",
                    models: [
                        {
                            name: "Beast"
                        },
                        {
                            name: "MMX"
                        },
                        {
                            name: "BST"
                        }
                    ]
                },
                {
                    name: "Manac Trailers",
                    models: [
                        {
                            name: "Ultralite"
                        },
                        {
                            name: "Darkwing"
                        },
                        {
                            name: "UltraPlate"
                        }
                    ]
                },
                {
                    name: "MAC Trailer Manufacturing",
                    models: [
                        {
                            name: "Step Deck"
                        },
                        {
                            name: "Aluminum Flatbed"
                        },
                        {
                            name: "Steel Flatbed"
                        }
                    ]
                },
                {
                    name: "Vanguard National Trailer Corporation",
                    models: [
                        {
                            name: "Combo Flatbed"
                        },
                        {
                            name: "Max Flatbed"
                        }
                    ]
                },
                {
                    name: "Dorsey Trailer",
                    models: [
                        {
                            name: "Combo Giant"
                        },
                        {
                            name: "Steel Flatbed"
                        },
                        {
                            name: "Steel Drop Deck"
                        }
                    ]
                },
                {
                    name: "Trail King Industries",
                    models: [
                        {
                            name: "TK80"
                        },
                        {
                            name: "TK70"
                        },
                        {
                            name: "Hydraulic Detachable"
                        }
                    ]
                },
                {
                    name: "Doepker Trailers",
                    models: [
                        {
                            name: "Super B-Train"
                        },
                        {
                            name: "Flat Deck"
                        },
                        {
                            name: "Step Deck"
                        }
                    ]
                },
                {
                    name: "Timpte Inc.",
                    models: [
                        {
                            name: "Drop Deck"
                        },
                        {
                            name: "Aluminum Flatbed"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "Travel Trailer",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Adria Mobil",
                    models: [
                        {
                            name: "Adora"
                        },
                        {
                            name: "Alpina"
                        },
                        {
                            name: "Action"
                        }
                    ]
                },
                {
                    name: "Airstream",
                    models: [
                        {
                            name: "Flying Cloud"
                        },
                        {
                            name: "Basecamp"
                        },
                        {
                            name: "Caravel"
                        }
                    ]
                },
                {
                    name: "Allen Camper Manufacturing",
                    models: [
                        {
                            name: "Falcon"
                        },
                        {
                            name: "Arrow"
                        }
                    ]
                },
                {
                    name: "Armadillo Trailer Manufacturing",
                    models: [
                        {
                            name: "Armadillo 13"
                        },
                        {
                            name: "Backpack"
                        }
                    ]
                },
                {
                    name: "Bigfoot Industries",
                    models: [
                        {
                            name: "2500 Series"
                        },
                        {
                            name: "3000 Series"
                        }
                    ]
                },
                {
                    name: "Bowlus",
                    models: [
                        {
                            name: "Heritage Edition"
                        },
                        {
                            name: "Terra Firma"
                        }
                    ]
                },
                {
                    name: "Braxton Creek",
                    models: [
                        {
                            name: "Bushwhacker"
                        },
                        {
                            name: "LX"
                        },
                        {
                            name: "Free Solo"
                        }
                    ]
                },
                {
                    name: "Casita",
                    models: [
                        {
                            name: "Independence"
                        },
                        {
                            name: "Liberty"
                        },
                        {
                            name: "Spirit"
                        }
                    ]
                },
                {
                    name: "Chinook RV",
                    models: [
                        {
                            name: "Dream"
                        },
                        {
                            name: "Countryside"
                        }
                    ]
                },
                {
                    name: "Coachmen",
                    models: [
                        {
                            name: "Catalina"
                        },
                        {
                            name: "Freedom Express"
                        },
                        {
                            name: "Clipper"
                        }
                    ]
                },
                {
                    name: "Coleman",
                    models: [
                        {
                            name: "Lantern"
                        },
                        {
                            name: "Light LX"
                        },
                        {
                            name: "Rubicon"
                        }
                    ]
                },
                {
                    name: "Cortes Campers",
                    models: [
                        {
                            name: "17-foot"
                        },
                        {
                            name: "16-foot"
                        }
                    ]
                },
                {
                    name: "CrossRoads RV",
                    models: [
                        {
                            name: "Volante"
                        },
                        {
                            name: "Sunset Trail"
                        },
                        {
                            name: "Zinger"
                        }
                    ]
                },
                {
                    name: "Cruiser RV",
                    models: [
                        {
                            name: "Embrace"
                        },
                        {
                            name: "Radiance"
                        },
                        {
                            name: "Shadow Cruiser"
                        }
                    ]
                },
                {
                    name: "Dutchmen RV",
                    models: [
                        {
                            name: "Aspen Trail"
                        },
                        {
                            name: "Kodiak"
                        },
                        {
                            name: "Coleman Lantern"
                        }
                    ]
                },
                {
                    name: "East to West RV",
                    models: [
                        {
                            name: "Alta"
                        },
                        {
                            name: "Della Terra"
                        },
                        {
                            name: "Silver Lake"
                        }
                    ]
                },
                {
                    name: "EcoCampor",
                    models: [
                        {
                            name: "Off-Road Camper"
                        },
                        {
                            name: "Teardrop"
                        }
                    ]
                },
                {
                    name: "Eggcamper",
                    models: [
                        {
                            name: "EggCamper"
                        }
                    ]
                },
                {
                    name: "Elddis",
                    models: [
                        {
                            name: "Avante"
                        },
                        {
                            name: "Affinity"
                        },
                        {
                            name: "Crusader"
                        }
                    ]
                },
                {
                    name: "Ember RV",
                    models: [
                        {
                            name: "Overland Series"
                        },
                        {
                            name: "Touring Edition"
                        }
                    ]
                },
                {
                    name: "Escape Trailer",
                    models: [
                        {
                            name: "Escape 5.0"
                        },
                        {
                            name: "Escape 21NE"
                        }
                    ]
                },
                {
                    name: "Escapod Trailers",
                    models: [
                        {
                            name: "Topo Series"
                        },
                        {
                            name: "Topper"
                        }
                    ]
                },
                {
                    name: "Eveland’s, Inc",
                    models: [
                        {
                            name: "Scamp 13"
                        },
                        {
                            name: "Scamp 16"
                        }
                    ]
                },
                {
                    name: "Forest River",
                    models: [
                        {
                            name: "R-Pod"
                        },
                        {
                            name: "Salem"
                        },
                        {
                            name: "Rockwood"
                        }
                    ]
                },
                {
                    name: "Genesis Supreme RV",
                    models: [
                        {
                            name: "Supreme 30"
                        },
                        {
                            name: "Genesis 26"
                        }
                    ]
                },
                {
                    name: "Grand Design",
                    models: [
                        {
                            name: "Imagine"
                        },
                        {
                            name: "Reflection"
                        },
                        {
                            name: "Transcend"
                        }
                    ]
                },
                {
                    name: "Gulf Stream Coach",
                    models: [
                        {
                            name: "Vintage Cruiser"
                        },
                        {
                            name: "Envision"
                        },
                        {
                            name: "Ameri-Lite"
                        }
                    ]
                },
                {
                    name: "Happier Camper",
                    models: [
                        {
                            name: "HC1"
                        },
                        {
                            name: "Traveler"
                        }
                    ]
                },
                {
                    name: "Heartland RV",
                    models: [
                        {
                            name: "Mallard"
                        },
                        {
                            name: "Pioneer"
                        },
                        {
                            name: "North Trail"
                        }
                    ]
                },
                {
                    name: "Highland Ridge RV",
                    models: [
                        {
                            name: "Open Range"
                        },
                        {
                            name: "Mesa Ridge"
                        }
                    ]
                },
                {
                    name: "inTech RV",
                    models: [
                        {
                            name: "Luna"
                        },
                        {
                            name: "Sol"
                        }
                    ]
                },
                {
                    name: "Jayco",
                    models: [
                        {
                            name: "Jay Flight"
                        },
                        {
                            name: "White Hawk"
                        },
                        {
                            name: "Eagle"
                        }
                    ]
                },
                {
                    name: "K-Z RV",
                    models: [
                        {
                            name: "Escape"
                        },
                        {
                            name: "Sportsmen"
                        },
                        {
                            name: "Durango"
                        }
                    ]
                },
                {
                    name: "Keystone RV Company",
                    models: [
                        {
                            name: "Springdale"
                        },
                        {
                            name: "Passport"
                        },
                        {
                            name: "Bullet"
                        }
                    ]
                },
                {
                    name: "Lance Camper",
                    models: [
                        {
                            name: "1985 Travel Trailer"
                        },
                        {
                            name: "1575 Travel Trailer"
                        }
                    ]
                },
                {
                    name: "Northwood Manufacturing",
                    models: [
                        {
                            name: "Arctic Fox"
                        },
                        {
                            name: "Nash"
                        }
                    ]
                },
                {
                    name: "nüCamp RV",
                    models: [
                        {
                            name: "TAB 400"
                        },
                        {
                            name: "TAG XL"
                        }
                    ]
                },
                {
                    name: "Oliver Travel Trailers",
                    models: [
                        {
                            name: "Legacy Elite"
                        },
                        {
                            name: "Legacy Elite II"
                        }
                    ]
                },
                {
                    name: "Outdoors RV Manufacturing",
                    models: [
                        {
                            name: "Timber Ridge"
                        },
                        {
                            name: "Black Stone"
                        }
                    ]
                },
                {
                    name: "Palomino RV",
                    models: [
                        {
                            name: "Puma"
                        },
                        {
                            name: "Solaire"
                        }
                    ]
                },
                {
                    name: "Prime Time Manufacturing",
                    models: [
                        {
                            name: "Avenger"
                        },
                        {
                            name: "Sanibel"
                        },
                        {
                            name: "Tracer"
                        }
                    ]
                },
                {
                    name: "Riverside RV",
                    models: [
                        {
                            name: "Retro"
                        },
                        {
                            name: "Mountaineer"
                        }
                    ]
                },
                {
                    name: "Roulottes Prolite",
                    models: [
                        {
                            name: "Prolite Plus"
                        },
                        {
                            name: "Prolite Eco"
                        }
                    ]
                },
                {
                    name: "Safari Condo",
                    models: [
                        {
                            name: "Alto"
                        },
                        {
                            name: "S.O.B."
                        }
                    ]
                },
                {
                    name: "Shasta RV",
                    models: [
                        {
                            name: "Shasta Oasis"
                        },
                        {
                            name: "Phoenix"
                        }
                    ]
                },
                {
                    name: "Starcraft",
                    models: [
                        {
                            name: "Launch"
                        },
                        {
                            name: "Solstice"
                        }
                    ]
                },
                {
                    name: "Sunset Park RV",
                    models: [
                        {
                            name: "Sunset Trailers"
                        },
                        {
                            name: "Mystic"
                        }
                    ]
                },
                {
                    name: "Swift Leisure",
                    models: [
                        {
                            name: "Basecamp"
                        },
                        {
                            name: "Conqueror"
                        }
                    ]
                },
                {
                    name: "Travel Lite RV",
                    models: [
                        {
                            name: "Falcon"
                        },
                        {
                            name: "Super Lite"
                        }
                    ]
                },
                {
                    name: "Venture RV",
                    models: [
                        {
                            name: "Sonic"
                        },
                        {
                            name: "SportTrek"
                        }
                    ]
                },
                {
                    name: "Winnebago Industries",
                    models: [
                        {
                            name: "Micro Minnie"
                        },
                        {
                            name: "Winnie Drop"
                        },
                        {
                            name: "Travato"
                        }
                    ]
                }
            ]
        },
        {
            vehicleType: "ATV",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Can-Am motorcycles",
                    models: [
                        {
                            name: "Outlander"
                        },
                        {
                            name: "Renegade"
                        },
                        {
                            name: "Maverick"
                        }
                    ]
                },
                {
                    name: "Yamaha Motor Company",
                    models: [
                        {
                            name: "Grizzly"
                        },
                        {
                            name: "Raptor"
                        },
                        {
                            name: "Kodiak"
                        }
                    ]
                },
                {
                    name: "Suzuki",
                    models: [
                        {
                            name: "KingQuad"
                        },
                        {
                            name: "QuadSport"
                        },
                        {
                            name: "Ozark"
                        }
                    ]
                },
                {
                    name: "Polaris",
                    models: [
                        {
                            name: "Sportsman"
                        },
                        {
                            name: "Ranger"
                        },
                        {
                            name: "Scrambler"
                        }
                    ]
                },
                {
                    name: "Honda",
                    models: [
                        {
                            name: "FourTrax Rancher"
                        },
                        {
                            name: "FourTrax Foreman"
                        },
                        {
                            name: "TRX250X"
                        }
                    ]
                },
                {
                    name: "Arctic Cat",
                    models: [
                        {
                            name: "Alterra"
                        },
                        {
                            name: "Bearcat"
                        },
                        {
                            name: "Wildcat"
                        }
                    ]
                },
                {
                    name: "CFMoto",
                    models: [
                        {
                            name: "CFORCE"
                        },
                        {
                            name: "UFORCE"
                        }
                    ]
                },
                {
                    name: "Kymco",
                    models: [
                        {
                            name: "MXU"
                        },
                        {
                            name: "Demon"
                        },
                        {
                            name: "Mongoose"
                        }
                    ]
                },
                {
                    name: "Argo",
                    models: [
                        {
                            name: "XTV"
                        },
                        {
                            name: "Aurora"
                        }
                    ]
                },
                {
                    name: "Linhai",
                    models: [
                        {
                            name: "Linhai 300"
                        },
                        {
                            name: "Linhai 400"
                        }
                    ]
                },
                {
                    name: "Polaris Sportsman 850 High Lifter Edition",
                    models: [{}]
                }
            ]
        },
        {
            name: "Sportsman 850 High Lifter",
            vehicleType: "Snowmobile",
            icon: "ic_truck.svg",
            manufacturers: [
                {
                    name: "Arctic Cat",
                    models: [
                        {
                            name: "ZR"
                        },
                        {
                            name: "M8"
                        },
                        {
                            name: "XF"
                        }
                    ]
                },
                {
                    name: "Polaris",
                    models: [
                        {
                            name: "Indy"
                        },
                        {
                            name: "Rush"
                        },
                        {
                            name: "Switchback"
                        }
                    ]
                },
                {
                    name: "Lynx",
                    models: [
                        {
                            name: "Ranger"
                        },
                        {
                            name: "Xterrain"
                        },
                        {
                            name: "Adventure"
                        }
                    ]
                },
                {
                    name: "John Deere",
                    models: [
                        {
                            name: "Trailfire"
                        },
                        {
                            name: "Ski-Doo"
                        }
                    ]
                },
                {
                    name: "Mercury",
                    models: [
                        {
                            name: "Trail"
                        },
                        {
                            name: "Sport"
                        }
                    ]
                },
                {
                    name: "Yamaha",
                    models: [
                        {
                            name: "RX Warrior"
                        },
                        {
                            name: "Viper"
                        },
                        {
                            name: "Sidewinder"
                        }
                    ]
                }
            ]
        }
    ]
};
