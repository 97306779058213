import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { CustomModal } from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";

import AuthWrapper from "./AuthWrapper";
import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ForgotPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import { forgotPassFormValidationSchema, initialValues } from "./FormValidations";
import { LABLES, NAMES, PLACEHOLDERS } from "./AuthFieldsNames";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

const ForgetPassword = ({}) => {
    let { EMAIL } = LABLES;
    const history = useHistory();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [holdToken, setHoldToken] = useState("");
    const [holdEmail, setHoldEmail] = useState("");
    const [showOtpModal, setShowOtpModal] = useState(false);

    const formik = useFormik({
        initialValues: initialValues.forgotPasswordForm,
        validationSchema: forgotPassFormValidationSchema,
        onSubmit: (values) => {
            handleEmailSubmit(values);
        }
    });

    const handleEmailSubmit = async (values) => {
        const { email } = values;
        try {
            setIsLoading(true);
            let res = await ForgotPassword({ Email: email });

            setHoldEmail(email);
            setHoldToken(res?.TokenUuid);
            setShowOtpModal(true);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.FORGET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper>
                <div className="w-100">
                    <div className="w-100 ">
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <p className="custom-text">{"Forgot Password?"}</p>
                        </div>
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <p className="m-0 p-0 custom-label text-center">
                                {
                                    "Enter your registered Email address to get One Time Password (OTP)"
                                }
                            </p>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-center p-4">
                        <form className="w-100" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <TextInput
                                    placeholder={PLACEHOLDERS[EMAIL]}
                                    label={EMAIL}
                                    name={NAMES[EMAIL]}
                                    type={NAMES[EMAIL]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[EMAIL]]}
                                    labelClass="custom-label mb-0"
                                    className="hide-default-icon"
                                    autoFocus
                                />
                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={NAMES[EMAIL]}
                                    className="error"
                                />
                            </div>

                            <CustomButton
                                loading={isLoading}
                                color="primary"
                                title="Send OTP"
                                className="w-100 grad-primary"
                            />
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <Link to={APP_ROUTES.LOGIN}>
                                    <p className="font-clr-theme-secondary text-decoration-underline  text-white custom-auth-right-text">
                                        Back to Login
                                    </p>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </AuthWrapper>
            <CustomModal isOpen={showOtpModal} size="md">
                <div className="text-center">
                    <h2 className="clr-theme-primary">OTP Sent</h2>
                    <p className="my-4">
                        One Time Password has been sent to your provided <br /> email address.
                    </p>
                    <CustomButton
                        color="primary"
                        title="Continue"
                        className="custom-btn-sm"
                        onClick={() =>
                            history.push({
                                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                                    tokenId: holdToken
                                }),
                                state: { email: holdEmail }
                            })
                        }
                    />
                </div>
            </CustomModal>
        </>
    );
};

export default withRouter(ForgetPassword);
