import { VEHICLE_DATA } from "../pages/VehicleManagement/FilterData";

export const getManufacturerOptions = (vehicleType) => {
    if (!vehicleType) return [];
    const type = VEHICLE_DATA.data.find((item) => item.vehicleType === vehicleType.value);
    return type
        ? type.manufacturers.map((manufacturer) => ({
              value: manufacturer.name,
              label: manufacturer.name
          }))
        : [];
};

export const getModelOptions = (vehicleType, manufacturer) => {
    if (!vehicleType || !manufacturer) return [];
    const type = VEHICLE_DATA.data.find((item) => item.vehicleType === vehicleType.value);
    const man = type?.manufacturers.find((man) => man.name === manufacturer.value);
    return man
        ? man.models.map((model) => ({
              value: model.name,
              label: model.name
          }))
        : [];
};

// export const getYearOptions = (vehicleType, manufacturer, model) => {
//     if (!vehicleType || !manufacturer || !model) return [];
//     const type = VEHICLE_DATA.data.find((item) => item.vehicleType === vehicleType.value);
//     const man = type?.manufacturers.find((man) => man.name === manufacturer.value);
//     const mod = man?.models.find((mod) => mod.name === model.value);
//     return mod
//         ? mod.years.map((year) => ({
//               value: year,
//               label: year.toString()
//           }))
//         : [];
// };
