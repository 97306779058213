import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";
import { CONFIRMATION_MODAL_CONFIG, getConfirmationModalConfig } from "./ConfirmationModalConfig";

const ConfirmationModal = ({
    isOpen = false,
    modalType = "DELETE",
    hasCustomConfig = false,
    customTitle = "",
    customSubText = "",
    customYesBtnText = "",
    customNoBtnText = "",
    customImageSrc = "",
    onYes = () => {},
    onNo = () => {},
    yesLoading = false,
    noLoading = false,
    ...rest
}) => {
    let customConfigObj = hasCustomConfig
        ? {
              customTitle,
              customSubText,
              customYesBtnText,
              customNoBtnText,
              customImageSrc
          }
        : null;

    let Config = getConfirmationModalConfig(CONFIRMATION_MODAL_CONFIG, modalType, customConfigObj);

    return (
        <div>
            <CustomModal
                isOpen={isOpen}
                handleModalToggling={onNo}
                customButton={false}
                className="modal-border"
                {...rest}
            >
                <div className="d-flex flex-column align-items-center">
                    <img src={Config.ImageSrc} className="mb-2" />

                    <div className="mb-4">
                        <h4 className=" fw-bold my-3 text-center custom-title">{Config.Title}</h4>
                        {Config.SubText && (
                            <h5 className="my-3  custom-subText">{Config.SubText}</h5>
                        )}
                    </div>

                    <div className="d-flex justify-content-evenly btns-main-con mb-4">
                        <CustomButton
                            title={Config.NoButtonText}
                            className="me-3 custom-btn-sm fw-bold "
                            color="primary"
                            outline
                            onClick={onNo}
                            loading={noLoading}
                            disabled={yesLoading}
                        />
                        <CustomButton
                            title={Config.YesButtonText}
                            color="primary"
                            className="custom-btn-sm shadow-theme fw-bold grad-primary"
                            onClick={onYes}
                            loading={yesLoading}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ConfirmationModal;
