import React, { useState } from "react";
import Slider from "react-slick";
import ImageGalleryLoader from "./ImageGalleryLoader";
import { getMediaPath } from "../../utils/mediaUtils";

import SliderNextArrow from "./SliderNextArrow";
import SliderPrevArrow from "./SliderPrevArrow";

const ImageGallery = ({
    images,
    settings,
    containerClasses,
    imageContainer,
    sliderImage,
    loading = false
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [blurred, setBlurred] = useState(false); // Track if the background is blurred

    if (loading) {
        return <ImageGalleryLoader />;
    }

    const sliderSettings = {
        customPaging: (index) => {
            const media = images[index];
            if (!media) return null;

            const isActive = index === activeIndex;

            return (
                <div
                    style={{
                        border: isActive ? "2px solid #0f984f" : "none",
                        borderRadius: "0.7rem"
                    }}
                >
                    <img
                        src={getMediaPath(media)}
                        alt={`Thumbnail ${index + 1}`}
                        className="thumbnail"
                    />
                </div>
            );
        },
        dots: true,
        dotsClass: "custom-vertical-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        waitForAnimate: false,
        responsive: [
            {
                breakpoint: 682,
                settings: {
                    dots: false,
                    dotsClass: "slick-dots slick-thumb",
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    autoplay: true,
                    nextArrow: <SliderNextArrow />,
                    prevArrow: <SliderPrevArrow />
                }
            }
        ]
    };

    const adjustedSettings = {
        ...sliderSettings,
        infinite: images?.length > 1,
        dots: images?.length > 1,
        beforeChange: (_, next) => {
            setActiveIndex(next);
            setBlurred(true); // Apply blur immediately when the image changes
        }
    };

    const activeImageUrl = getMediaPath(images?.[activeIndex]);

    return (
        <div className={`${containerClasses} slider-container`}>
            {/* Blurred background layer */}
            <div
                className={`blurred-background blur ${blurred ? "" : ""}`} // Add 'blur' class if blurred
                style={{
                    backgroundImage: `url(${activeImageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    ...(images?.length < 2 && { width: "100%" })
                }}
            />
            <Slider {...adjustedSettings}>
                {images?.map((image, index) => (
                    <div key={index} className={`${imageContainer} slider-image`}>
                        <img
                            src={getMediaPath(image)}
                            alt={`Image ${index + 1}`}
                            className="image"
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageGallery;

// import React from "react";
// import Slider from "react-slick";

// import ImageGalleryLoader from "./ImageGalleryLoader";
// import { getMediaPath } from "../../utils/mediaUtils";

// const ImageGallery = ({
//     images,
//     settings,
//     containerClasses,
//     imageContainer,
//     sliderImage,
//     loading = false
// }) => {
//     if (loading) {
//         return <ImageGalleryLoader />;
//     }

//     const adjustedSettings = {
//         ...settings,
//         infinite: images?.length > 1,
//         dots: images?.length > 1
//     };

//     return (
//         <div className={`${containerClasses}`}>
//             <Slider {...adjustedSettings}>
//                 {images?.map((image, index) => (
//                     <div key={index} className={`${imageContainer}`}>
//                         <img
//                             src={getMediaPath(image)}
//                             alt={`Image ${index + 1}`}
//                             className={`${sliderImage}`}
//                         />
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// };

// export default ImageGallery;
