import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import successModal from "../../assets/images/app/plate-talk/success-modal-icon.svg";

import ProfileDataStructureUI from "./ProfileDataStructureUI";
import { LABLES, NAMES } from "./AccountFieldsNames";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { ConfirmationModal, InformationModal } from "../../components/CustomModal";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { UpdateUserById } from "../../api/api.service";
import { showToast, SUCCESS } from "../../utils/toastUtils";
import { getMe } from "../../store/actions";

const Profile = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState(null);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    let { useMutate } = useRequestProcessor();
    let { mutate, isLoading } = useMutate("", getKey([MODULE_KEYS.PROFILE_UPDATED]), (data) =>
        UpdateUserById(data)
    );

    const handleFormSubmit = async (values) => {
        setIsFormSubmitting(true);
        const { fullName } = values;
        const bodyData = {
            FullName: fullName
        };
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsFormSubmitting(false);
                await dispatch(getMe());
                setShowModal(true);
            },
            onError: (err) => {
                setIsFormSubmitting(false);
            }
        });
    };

    return (
        <>
            <Container fluid>
                <ProfileDataStructureUI
                    FIELDS_TITLE={LABLES}
                    onSubmitHandling={handleFormSubmit}
                    isLoading={isLoading}
                />
            </Container>

            <InformationModal
                size="md"
                isOpen={showModal}
                Title={"Your profile has been updated successfully"}
                confirmButtonText="Done"
                ImageSrc={successModal}
                onConfirmClick={() => setShowModal(false)}
            />
        </>
    );
};

export default Profile;
