import { injectBaseConstantMethods } from "./BaseConstants";

export const USER_STATUS = {
    VERIFIED: "Verified",
    SUSPENDED: "Suspended",
    UNVERIFIED: "Unverified"
};

export const USER_STATUS_FILTER = {
    ACTIVE: "Active",
    SUSPENDED: "Block"
};

const displayTextKeys = {
    [USER_STATUS.VERIFIED]: "Active",
    [USER_STATUS.UNVERIFIED]: "Active",
    [USER_STATUS.SUSPENDED]: "Block"
};

const labelClass = {
    [USER_STATUS.VERIFIED]: "cs-badge-active",
    [USER_STATUS.UNVERIFIED]: "cs-badge-active",
    [USER_STATUS.SUSPENDED]: "cs-badge-inactive"
};

export default injectBaseConstantMethods(USER_STATUS, displayTextKeys, labelClass);
