import React from "react";
import { DebounceInput } from "react-debounce-input";

const SearchBox = (props) => {
    const {
        className = "",
        placeholder = "Search",
        value = "",
        onChange = () => {},
        disable = false
    } = props;
    return (
        <div className={`body-search  bv-search-box w-100 ${className} `}>
            <div className="position-relative">
                <DebounceInput
                    // element={<input />}
                    debounceTimeout={500}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className="form-control w-100"
                    disabled={disable}
                />
                <span className="bx bx-search " />
            </div>
        </div>
    );
};

export default SearchBox;
