import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetUsers, UpdateUserStatusById } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles, AdStatus, SubscriptionType } from "../../constants";
import { MODAL_CONTENT_CONFIG } from "./ModalContentConfig";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import { CustomSwitchInput, SwitchInput } from "../../components/Input";
import { SUCCESS, showToast } from "../../utils/toastUtils";
import { ConfirmationModal } from "../../components/CustomModal";
import { getStatus, findStatusChecked } from "./utils";
import { formatKey, truncateString } from "../../utils/commonUtils";
import { useQueryClient } from "@tanstack/react-query";
import { getMediaPath } from "../../utils/mediaUtils";
import { monthYear } from "../../utils/dateUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";
import { SUBSCRIPTION_TYPE } from "../../constants/SubscriptionType";
import { SummaryCard } from "../../components/SummaryCard";
import DateRangePicker from "./DateRangePicker";
import AutoComplete from "../../components/AutoComplete/AutoComplete";
import { customStyles } from "./CustomStyles";
import UserStatus, { USER_STATUS_FILTER } from "../../constants/UserStatus";
import { filterOptions } from "./Config";

const COLUMNS = {
    NAME: "Name",
    DATE_JOINED: "Date Joined",
    STATE: "State",
    CITY: "City",
    REPORTS_COUNT: "Reports count",
    VEHICLE: "Vehicles",
    SUBSCRIPTION_PLAN: "Subscription Plan",
    STATUS: "Status",
    ACTION: "Action"
};

const recordUpdateSuccessMessage = "Status has been updated successfully";
const format = dateTimeFormat.appDateFormat;

const UserManagement = () => {
    const queryClient = useQueryClient();
    const history = useHistory();

    const [modalConfig, setModalConfig] = useState(null);
    const [isUserStatusUpdating, setIsUserStatusUpdating] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({ status: "", id: "" });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const formattedStartDate = monthYear("YYYY-MM-DD", startDate);
    const formattedEndDate = monthYear("YYYY-MM-DD", endDate);
    const {
        isFetching,
        page,
        handlePageClick,
        total,
        data,
        limit,
        handleSortingChange,
        refetch,
        searchText,
        handleSearchChange,
        setPageNumberOne
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.USER_MANAGEMENT]),
        requestFn: GetUsers,
        dataKey: "Data",
        totalKey: "Count",
        params: {
            ...(selectedOption && {
                Status: selectedOption?.value
            }),
            ...(startDate && { StartDate: formattedStartDate }),
            ...(endDate && { EndDate: formattedEndDate })
        }
    });

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey(), (data) =>
        UpdateUserStatusById({ Id: selectedRecord?.id, bodyData: data })
    );

    const handleChange = (selected) => {
        setPageNumberOne();
        setSelectedOption(selected);
    };

    const handleActionInit = (id, status) => {
        setSelectedRecord({ id, status });
        let statusToSet = getStatus(status);
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    const handleUserStatusUpdate = () => {
        setIsUserStatusUpdating(true);
        let statusToSet = getStatus(selectedRecord.status);

        const bodyData = { Status: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsUserStatusUpdating(false);
                showToast(SUCCESS, recordUpdateSuccessMessage);

                setModalConfig(null);
                refetch();
            },
            onError: (err) => {
                // showToast(ERROR, err?.Message)
                setIsUserStatusUpdating(false);
            }
        });
    };

    const handleChangeStart = (date) => {
        setPageNumberOne();
        setStartDate(date);
    };

    const handleChangeEnd = (date) => {
        setPageNumberOne();
        setEndDate(date);
    };

    return (
        <div>
            <Helmet>
                <title>{PageTitles.USER_MANAGEMENT}</title>
            </Helmet>

            <SummaryCard colClassName={"mt-2"}>
                <Row className="p-2 mt-2 ">
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-100 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="2" lg="2"></Col>

                    <Col sm="12" md="7" lg="7" align="right">
                        <div className=" d-flex justify-content-end align-items-center ">
                            <AutoComplete
                                isClearable
                                customStyles={customStyles.statusFilter}
                                options={filterOptions}
                                onChange={handleChange}
                                value={selectedOption}
                                placeholder="Status"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#2795ae"
                                    }
                                })}
                                maxMenuHeight={220}
                                classNamePrefix="status-header-search-admin"
                            />
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                handleChangeStart={handleChangeStart}
                                handleChangeEnd={handleChangeEnd}
                                icon="bx bx-calendar"
                                iconClass="icon-class"
                            />
                        </div>
                    </Col>
                </Row>

                <DataTable
                    isHeightRequired={true}
                    tableContainerClass="mt-2 "
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMNS.NAME,
                            headerClass: "column-header px-4",
                            className: "px-4",
                            render: (data) => (
                                <div className="user">
                                    <div className="image">
                                        <img src={getMediaPath(data?.Media)} alt="eye-icon" />
                                    </div>
                                    <div>{data?.FullName}</div>
                                </div>
                            )
                        },
                        {
                            title: COLUMNS.DATE_JOINED,
                            headerClass: "column-header",
                            render: (data) => (
                                <div>
                                    {data?.CreatedAt ? monthYear(format, data?.CreatedAt) : "-"}
                                </div>
                            )
                        },
                        {
                            title: COLUMNS.STATE,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.AddressState || "-"}</div>
                        },
                        {
                            title: COLUMNS.CITY,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.AddressCity || "-"}</div>
                        },

                        {
                            title: COLUMNS.VEHICLE,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.VehicleCount || "-"}</div>
                        },
                        {
                            title: COLUMNS.SUBSCRIPTION_PLAN,
                            headerClass: "column-header",
                            render: (data) => (
                                <div
                                    className={`job-status-common ${
                                        data?.Subscription?.Product?.Name == SUBSCRIPTION_TYPE.FREE
                                            ? SubscriptionType.getLabelClass(
                                                  data?.Subscription?.Product?.Name
                                              )
                                            : "cs-badge-vibrant-orange"
                                    }`}
                                >
                                    {data?.Subscription?.Product?.Name || "-"}
                                </div>
                            )
                        },

                        {
                            title: COLUMNS.STATUS,
                            headerClass: "column-header",
                            render: (data) =>
                                (
                                    <div className="mb-0">
                                        <CustomSwitchInput
                                            label="Block"
                                            isChecked={findStatusChecked(data?.Status)}
                                            size="small"
                                            onClick={() => handleActionInit(data?.Id, data?.Status)}
                                        />
                                    </div>
                                ) || "-"
                        },

                        {
                            title: COLUMNS.ACTION,
                            headerClass: "column-header",
                            render: (data) => {
                                return (
                                    <div className="d-flex ">
                                        <img
                                            style={{
                                                backgroundColor: "#D7D7D",
                                                filter: " grayscale(100%)"
                                            }}
                                            src={viewIcon}
                                            role="button"
                                            alt={"details icon"}
                                            onClick={() => {
                                                queryClient.invalidateQueries({
                                                    queryKey: [MODULE_KEYS.USER_MANAGEMENT_DETAILS]
                                                });
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.USER_MANAGEMENT_DETAILS,
                                                        {
                                                            userID: data?.Id
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </SummaryCard>

            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customSubText={modalConfig?.modalSubText}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onYes={handleUserStatusUpdate}
                onNo={handleActionCancellation}
                yesLoading={isUserStatusUpdating}
            />
        </div>
    );
};
export default UserManagement;
