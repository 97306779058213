import React from "react";
import PropTypes from "prop-types";

import "./Style.scss";

const FormikErrorText = (props) => {
    let { formikInstance, fieldName, className = "error" } = props;

    return (
        <>
            {formikInstance.touched[fieldName] && formikInstance.errors[fieldName] ? (
                <div className={className}>{formikInstance.errors[fieldName]}</div>
            ) : null}
        </>
    );
};

FormikErrorText.propTypes = {
    formikInstance: PropTypes.any,
    fieldName: PropTypes.any,
    className: PropTypes.string
};

export default FormikErrorText;
