import React from "react";
import { Container, Row } from "reactstrap";

import "./Styles.scss";
import APP_CONFIG from "../../APP_CONFIG";

const AuthWrapper = ({ className = "", children }) => {
    return (
        <div className={`${className} app-auth-wrapper d-flex  min-vh-100`}>
            {/* Left Section with Background Image */}
            <div
                className="auth-wrap-left-content"
                style={{
                    background: `url(${APP_CONFIG.IMAGES.LOGIN_LEFT_BG_IMAGE}) no-repeat center / cover`,
                    minHeight: "100vh",
                    overflowY: "auto"
                }}
            >
                <Container className=" main-image child-container ">
                    <div className="d-flex flex-column justify-content-evenly h-100">
                        <h1 className="text-black left-container-position">
                            <span className="d-block" style={{ fontWeight: "500" }}>
                                <b>Safety </b> in Your <b>Hands,</b>
                            </span>
                            <span className="d-block" style={{ fontWeight: "500" }}>
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        textDecorationColor: "#00FF00"
                                    }}
                                >
                                    <b> Connection</b>
                                </span>{" "}
                                at Your <br />
                                <b> Fingertips</b>
                            </span>
                        </h1>
                    </div>
                </Container>

                <Container className=" child-container  auth-right-container blurred-background ">
                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                        <div
                            style={{
                                background: `url(${APP_CONFIG.IMAGES.LOGIN_RIGHT_IMAGE}) no-repeat center/cover`,
                                height: "100px",
                                width: "200px" // Adjust the height as needed
                            }}
                        ></div>
                    </div>

                    <div className=" w-100 h-100 d-flex align-items-center justify-content-center ">
                        {children}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default AuthWrapper;
