import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Row, Container, Label, FormGroup } from "reactstrap";

import { CustomButton } from "../../components/CustomButton";
import { updateProfileValidationSchema } from "./FormValidations";
import { ImageBox } from "../../components/ImageBox";
import { EditableDetail } from "../../components/EditableDetail";
import { PageTitles } from "../../constants";
import APP_CONFIG from "../../APP_CONFIG";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { TextInput } from "../../components/Input";
import { NAMES, PLACEHOLDERS } from "./AccountFieldsNames";

const Profile = ({ FIELDS_TITLE = {}, onSubmitHandling = () => {}, isLoading }) => {
    let { FULL_NAME, EMAIL } = FIELDS_TITLE;
    const { FullName, Email } = useSelector((store) => store?.userAuth?.user);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { fullName: FullName, email: Email },
        validationSchema: updateProfileValidationSchema,
        onSubmit: (values) => {
            onSubmitHandling(values);
        }
    });

    return (
        <div className="">
            <Helmet>
                <title>{PageTitles.ACCOUNT}</title>
            </Helmet>

            <Card className="custom-card mt-3">
                <form onSubmit={formik.handleSubmit}>
                    <Container fluid>
                        <CardBody className="account-responsiveness">
                            <Row>
                                <Col md={6}>
                                    <TextInput
                                        placeholder={PLACEHOLDERS[FULL_NAME]}
                                        label={FULL_NAME}
                                        name={NAMES[FULL_NAME]}
                                        type={NAMES[FULL_NAME]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[NAMES[FULL_NAME]]}
                                        labelClass="custom-label mb-0"
                                        className="hide-default-icon text-input"
                                        autoFocus
                                    />

                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={NAMES[FULL_NAME]}
                                        className="error"
                                    />
                                </Col>
                                <Col md={6}>
                                    <TextInput
                                        placeholder={PLACEHOLDERS[EMAIL]}
                                        label={EMAIL}
                                        name={NAMES[EMAIL]}
                                        type={NAMES[EMAIL]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[NAMES[EMAIL]]}
                                        labelClass="custom-label mb-0"
                                        className="hide-default-icon text-input"
                                        disabled={true}
                                        autoFocus
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={NAMES[EMAIL]}
                                        className="error"
                                    />
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-end">
                                <Col sm="12" md="3" lg="3" className="d-flex justify-content-end">
                                    <CustomButton
                                        loading={isLoading}
                                        type={"submit"}
                                        title="Update Profile"
                                        color="primary"
                                        className="custom-btn-md  mt-2 grad-primary"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Container>
                </form>
            </Card>
        </div>
    );
};

export default Profile;
