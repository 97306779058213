export const customStyles = {
    statusFilter: {
        container: (provided) => ({
            display: "inline-block",
            position: "relative",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
            width: "100%",
            minHeight: "40px",
            ...provided
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: "40px",
            borderColor: "#0f984f",
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: "10px",
            "&:hover": {
                borderWidth: "2px",
                borderColor: "#0f984f"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: 500,
            color: state.isSelected ? "#fff" : "#0f984f",
            background: state.isSelected ? "#0f984f" : "#fff",
            fontSize: "1rem",
            fontFamily: "Onest",
            textAlign: "center",
            "&:hover": {
                color: "#fff",
                background: "linear-gradient(185.12deg, #4bee8d -74.08%, #14c667 73.64%)"
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: "center",
            width: "100%"
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: "center",
            width: "100%" // Ensures single selected value remains centered
        }),
        input: (provided) => ({
            ...provided,
            textAlign: "center" // Centers the typing text and placeholder
        })
    }
};
