import { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import successModal from "../../assets/images/app/plate-talk/success-modal-icon.svg";

import { Card, Container, CardBody, Row, Col } from "reactstrap";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { resetPassFormValidationSchema, initialValues } from "./FormValidations";
import { PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { ConfirmationModal, CustomModal, InformationModal } from "../../components/CustomModal";
import { LABLES, NAMES, PLACEHOLDERS } from "./AccountFieldsNames";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { ChangePasswordAccount } from "../../api/api.service";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { Helmet } from "react-helmet";
import { PageTitles } from "../../constants";
import { ERROR, showToast } from "../../utils/toastUtils";

const ChangePassword = () => {
    let { id: userID } = useSelector((state) => state.userAuth.user);
    let { PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD } = LABLES;

    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState(null);

    const formik = useFormik({
        initialValues: initialValues.changePassword,
        validationSchema: resetPassFormValidationSchema,
        onSubmit: (values, { resetForm }) => {
            handleFormSubmit(values, resetForm);
        }
    });

    let { useMutate } = useRequestProcessor();
    let { mutate, isLoading } = useMutate(
        "",
        getKey([MODULE_KEYS.ACCOUNT_CHANGE_PASSWORD]),
        (data) => ChangePasswordAccount(data)
    );

    const handleFormSubmit = (values, resetForm) => {
        const { oldPassword, newPassword } = values;
        let bodyData = {
            OldPassword: oldPassword,
            NewPassword: newPassword
        };
        mutate(bodyData, {
            onSuccess: (res) => {
                setShowModal(true);
                resetForm();
            }
        });
    };

    return (
        <>
            <div className="">
                <Helmet>
                    <title>{PageTitles.ACCOUNT}</title>
                </Helmet>

                <Card className="custom-card mt-3">
                    <form onSubmit={formik.handleSubmit}>
                        <Container fluid>
                            <CardBody className="account-responsiveness">
                                <Row>
                                    <Col sm="12" md="4" lg="4">
                                        <PasswordInput
                                            placeholder={PLACEHOLDERS[PASSWORD]}
                                            label={PASSWORD}
                                            name={NAMES[PASSWORD]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[NAMES[PASSWORD]]}
                                            className="text-input"
                                            labelClass="custom-label mb-0"
                                            iconClass={"iconClass"}
                                            size="lg"
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={NAMES[PASSWORD]}
                                            className="error mt-1"
                                        />
                                    </Col>
                                    <Col sm="12" md="4" lg="4">
                                        <PasswordInput
                                            placeholder={PLACEHOLDERS[NEW_PASSWORD]}
                                            label={NEW_PASSWORD}
                                            name={NAMES[NEW_PASSWORD]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[NAMES[NEW_PASSWORD]]}
                                            className="text-input"
                                            labelClass="custom-label mb-0"
                                            iconClass={"iconClass"}
                                            size="lg"
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={NAMES[NEW_PASSWORD]}
                                            className="error mt-1"
                                        />
                                    </Col>
                                    <Col sm="12" md="4" lg="4">
                                        <PasswordInput
                                            placeholder={PLACEHOLDERS[CONFIRM_PASSWORD]}
                                            label={CONFIRM_PASSWORD}
                                            name={NAMES[CONFIRM_PASSWORD]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[NAMES[CONFIRM_PASSWORD]]}
                                            className="text-input"
                                            labelClass="custom-label mb-0"
                                            iconClass={"iconClass"}
                                            size="lg"
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={NAMES[CONFIRM_PASSWORD]}
                                            className="error mt-1"
                                        />
                                    </Col>
                                </Row>

                                <Row className="d-flex justify-content-end">
                                    <Col
                                        sm="12"
                                        md="3"
                                        lg="3"
                                        className="d-flex justify-content-end mt-2"
                                    >
                                        <CustomButton
                                            loading={isLoading}
                                            type="submit"
                                            title="Update Password"
                                            className="custom-btn-md mt-2 grad-primary"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Container>
                    </form>

                    <InformationModal
                        size="md"
                        isOpen={showModal}
                        Title={"Your password has been updated successfully"}
                        confirmButtonText="Done"
                        ImageSrc={successModal}
                        onConfirmClick={() => setShowModal(false)}
                    />
                </Card>
            </div>
        </>
    );
};

export default ChangePassword;
