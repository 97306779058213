import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({
    startDate,
    endDate,
    handleChangeStart,
    handleChangeEnd,
    iconClass,
    icon
}) => {
    const today = new Date();

    return (
        <div
            className={
                "d-flex justify-content-end gap-2 date-picker-contanier align-items-center my-1 mx-2"
            }
        >
            <div className="date-picker-wrapper">
                <DatePicker
                    selected={startDate}
                    onChange={handleChangeStart}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    isClearable
                    maxDate={endDate ? endDate : today} // Restrict to past dates
                />
                {startDate || endDate ? null : <i className={`${icon} calendar-icon`}></i>}
            </div>
            {/* Line between the DatePickers */}
            <div className="line"></div>
            <div className="date-picker-wrapper">
                <DatePicker
                    minDate={startDate ? startDate : null} // Only allow end date if start date is selected
                    selected={endDate}
                    onChange={handleChangeEnd}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today} // Restrict to past dates
                    placeholderText="End Date"
                    isClearable
                />
                {startDate || endDate ? null : <i className={`${icon} calendar-icon`}></i>}
            </div>
        </div>
    );
};

export default DateRangePicker;
