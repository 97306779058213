import * as Yup from "yup";

export const initialValues = {
    loginForm: {
        email: "",
        password: ""
    },
    forgotPasswordForm: {
        email: ""
    },
    resetPasswordForm: {
        newPassword: "",
        confirmPassword: ""
    }
};

export const loginFormValidationSchema = Yup.object({
    email: Yup.string()
        .email("Enter a valid email address")
        .required("Enter email address")
        .min(6, "Too short email!")
        .max(50, "Too long email!"),

    password: Yup.string().required("Enter Password")
});

export const forgotPassFormValidationSchema = Yup.object({
    email: Yup.string()
        .email("Enter a valid email address")
        .required("Enter email address")
        .min(6, "Too short email!")
        .max(50, "Too long email!")
});

export const resetPassFormValidationSchema = Yup.object({
    newPassword: Yup.string()
        .required("Enter New Password")
        .min(6, "Password should be minimum 6 characters long")
        .max(25, "Password should be between 6 to 25 characters")
        .matches(
            /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,25}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Please make sure your passwords match")
        .required("Confirm Password is required")
});
