import React from "react";
import { Button } from "reactstrap";

import { Loader } from "../Loader";

const CustomButton = (props) => {
    const {
        className = "",
        loaderColor = "light",
        title,
        outline = false,
        loading = false,
        color,
        onClick,
        type,
        disabled,
        name,
        icon,
        iconClassname = ""
    } = props;
    return (
        <Button
            name={name}
            disabled={disabled}
            type={type}
            color={color}
            onClick={onClick}
            outline={outline}
            className={`custom-btn ${className} ${outline && "btn-outline-primary"}`}
        >
            {loading ? (
                <Loader size={"sm"} color={loaderColor} />
            ) : (
                <div className="btn-title-container d-flex justify-content-center align-items-center">
                    {icon && <div className={`btn-icon ${iconClassname}`}>{icon}</div>} {title}
                </div>
            )}
        </Button>
    );
};

export default CustomButton;
