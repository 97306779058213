import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

const LabeledDataItem = ({
    title = "-",
    desc = "-",
    includeHR = false,
    titleClass = "",
    descClass = "",
    rowClass = "",
    titleColProps = { xs: 4 },
    descColProps = { xs: 8 },
    icon = null,
    menuImg = null,
    hrClass = "",
    iconClass = "",
    imgClass = ""
}) => {
    const renderHorizontalRule = () => {
        if (!includeHR) return null;
        return (
            <Col xs={12}>
                <hr className={hrClass} />
            </Col>
        );
    };

    return (
        <>
            <Row className={rowClass}>
                <Col {...titleColProps}>
                    <div className="d-flex  align-items-center">
                        {icon ? (
                            <i className={`${icon} ${iconClass}`}></i>
                        ) : (
                            <img src={menuImg} className={imgClass} />
                        )}

                        <div className={titleClass}>{title}</div>
                    </div>
                </Col>
                <Col {...descColProps} className={descClass}>
                    {desc}
                </Col>
            </Row>
            {renderHorizontalRule()}
        </>
    );
};

LabeledDataItem.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    includeHR: PropTypes.bool,
    titleClass: PropTypes.string,
    descClass: PropTypes.string,
    rowClass: PropTypes.string,
    titleColProps: PropTypes.object,
    descColProps: PropTypes.object,
    hrClass: PropTypes.string,
    imgClass: PropTypes.string
};

LabeledDataItem.defaultProps = {
    title: "-",
    desc: "-",
    includeHR: false,
    titleClass: "",
    descClass: "",
    rowClass: "",
    titleColProps: { xs: 4 },
    descColProps: { xs: 8 },
    hrClass: "",
    imgClass: ""
};

export default LabeledDataItem;
