import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authProtectedRoutes } from "../../routes";
import { Loader } from "../../components/Loader";
import { Page500 } from "../../components/Utility";
import { getMe } from "../../store/actions";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import Breadcrumb from "../../components/Common/Breadcrumb2";

const DefaultLayout = ({ Layout }) => {
    let dispatch = useDispatch();
    const { tokenVerified, isLoading } = useSelector((state) => state.root);
    const { isAuthenticated } = useSelector((state) => state.userAuth);

    const Role = useSelector((state) => state?.userAuth?.user?.Type);

    useEffect(() => {
        if (isAuthenticated) {
            if (!tokenVerified) {
                dispatch(getMe()).catch(() => {});
            }
        }
    }, []);

    if (!isAuthenticated) {
        //:TODO: Need to fix
        // return <Redirect to={APP_ROUTES.LOGIN} />;
        window.location.replace(APP_ROUTES.LOGIN);
    }

    // :TODO: Need to change this, right now its just to execute the project
    const roleBasedRoutes = authProtectedRoutes.filter((item) => !item.permissions?.includes(Role));

    return tokenVerified ? (
        <Switch>
            {authProtectedRoutes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                            <Layout>
                                <div className="page-content">
                                    <div>
                                        <Breadcrumb />
                                    </div>
                                    <route.component {...props} />
                                </div>
                            </Layout>
                        )}
                    />
                );
            })}

            <Redirect to={APP_ROUTES.USER_MANAGEMENT} />
        </Switch>
    ) : !tokenVerified && isLoading ? (
        <Loader
            classes="vh-100"
            // :TODO:
            // showVerifyText={true}
        />
    ) : // <Page500 />
    null;
};

export default DefaultLayout;
