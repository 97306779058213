import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect, withRouter, Link } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { loginFormValidationSchema, initialValues } from "./FormValidations";
import { TextInput, PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { login } from "../../store/actions";
import AuthWrapper from "./AuthWrapper";
import PageTitles from "../../constants/PageTitles";
import { SUCCESS, showToast } from "../../utils/toastUtils";
import { LABLES, NAMES, PLACEHOLDERS } from "./AuthFieldsNames";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

const Login = ({}) => {
    let { EMAIL, PASSWORD } = LABLES;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: initialValues.loginForm,
        validationSchema: loginFormValidationSchema,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;
        try {
            setIsLoading(true);
            await dispatch(
                login({
                    Email: email,
                    Password: password
                })
            );
            showToast(SUCCESS, "Login successfully !");
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.USER_MANAGEMENT} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.LOGIN}</title>
            </Helmet>
            <AuthWrapper>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="custom-text">{"Welcome to PlateTalk"}</p>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-center p-4">
                        <form className="w-100" onSubmit={formik.handleSubmit}>
                            <div className="mb-1">
                                <TextInput
                                    placeholder={PLACEHOLDERS[EMAIL]}
                                    label={EMAIL}
                                    name={NAMES[EMAIL]}
                                    type={NAMES[EMAIL]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[EMAIL]]}
                                    labelClass="custom-label mb-0"
                                    className="hide-default-icon"
                                    autoFocus
                                />
                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={NAMES[EMAIL]}
                                    className="error"
                                />
                            </div>

                            <div>
                                <PasswordInput
                                    placeholder={PLACEHOLDERS[PASSWORD]}
                                    label={PASSWORD}
                                    name={NAMES[PASSWORD]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[PASSWORD]]}
                                    labelClass="custom-label mb-0"
                                />

                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={NAMES[PASSWORD]}
                                    className="error"
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-end mt-2">
                                <Link to={APP_ROUTES.FORGET_PASSWORD}>
                                    <p className="font-clr-theme-secondary text-decoration-underline  text-white custom-auth-right-text">
                                        Forgot your password ?
                                    </p>
                                </Link>
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                title="Login"
                                className="w-100 mt-4 grad-primary"
                                color="primary"
                            />
                        </form>
                    </div>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(Login);
