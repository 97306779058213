export let LABLES = {
    EMAIL: "Email",
    PASSWORD: "Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm New Password"
};

export let NAMES = {
    [LABLES.EMAIL]: "email",
    [LABLES.PASSWORD]: "password",
    [LABLES.NEW_PASSWORD]: "newPassword",
    [LABLES.CONFIRM_PASSWORD]: "confirmPassword"
};

export let PLACEHOLDERS = {
    [LABLES.EMAIL]: "Email Address",
    [LABLES.PASSWORD]: "Password",
    [LABLES.NEW_PASSWORD]: "New Password",
    [LABLES.CONFIRM_PASSWORD]: "Confirm New Password"
};
