import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";

const CustomSwitchInput = ({
    label = "Block",
    activeLabel = "Active",
    isChecked = false,
    size = "",
    onClick = () => {}
}) => {
    return (
        <div onClick={onClick}>
            <div className={`custom-switch ${isChecked ? "active" : ""} ${size}`}>
                {isChecked ? (
                    <>
                        <span className="switch-label">{activeLabel}</span>
                        <div className="switch-circle"></div>
                    </>
                ) : (
                    <>
                        <div className="switch-circle"></div>
                        <span className="switch-label">{label}</span>
                    </>
                )}
            </div>
        </div>
    );
};

CustomSwitchInput.propTypes = {
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    size: PropTypes.oneOf(["small", "large", ""]),
    onClick: PropTypes.func
};

export default CustomSwitchInput;
