import React from "react";
import { Input } from "reactstrap";

const TextInput = ({
    className = "hide-default-icon", // pass class hide-icon to remove icon css
    containerClasses = "",
    inputClasses = "",
    name,
    placeholder,
    type = "text",
    onChange,
    value,
    children,
    autoComplete = "off",
    icon = null,
    label = "",
    size = "",
    iconClass = "",
    labelClass = "",
    ...rest
}) => {
    return (
        <div className={`d-flex flex-column ${containerClasses}`}>
            {label && <p className={`input-label ${labelClass}`}>{label}</p>}
            <div className={`custom-text-input ${icon && "hide-default-icon"} ${className}`}>
                <Input
                    {...rest}
                    bsSize={size}
                    name={name}
                    className={`form-control ${inputClasses} ${icon && "input-icon-padding"}`}
                    placeholder={placeholder}
                    type={type}
                    onChange={onChange}
                    value={value}
                    autoComplete={autoComplete}
                />
                {icon && <div className={`input-icon cursor-pointer ${iconClass}`}>{icon}</div>}
                {children}
            </div>
        </div>
    );
};

export default TextInput;
