import React from "react";

import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import { RoutingTabs } from "../../components/RoutingTabs";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const Accounts = ({}) => {
    let CONFIG = {
        param: [],
        tabsConfig: [
            {
                title: "Profile",
                id: "1",
                defaultActive: true,
                path: APP_ROUTES.ACCOUNT_PROFILE,
                component: <Profile />
            },
            {
                title: "Change Password",
                id: "2",
                defaultActive: false,
                path: APP_ROUTES.ACCOUNT_CHANGE_PASSWORD,
                component: <ChangePassword />
            }
        ]
    };

    return (
        <div className="my-1">
            <RoutingTabs config={CONFIG} isPills isTabs={false} parentRoute={APP_ROUTES.ACCOUNT} />
        </div>
    );
};

export default Accounts;
