// baseCode
import PropTypes from "prop-types";
import React, { useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Import menuDropdown
import NotificationDropdown from "../../components/CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../../components/CommonForBoth/TopbarDropdown/ProfileMenu";

//Logo
import lglogo from "../../assets/images/app/plate-talk/lg-sidebar-logo.svg";
import smlogo from "../../assets/images/app/plate-talk/sm-sidebar-logo.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";

import APP_CONFIG from "../../APP_CONFIG";

const Header = (props) => {
    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function tToggle() {
        var body = document.body;

        if (window.screen.width <= 992) {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        }
    }

    return (
        <>
            <header id="page-topbar" className="gt-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box  d-md-block ">
                            <Link to="/" className="logo logo-light">
                                {/* Logo will be change with casita */}
                                <span className="logo-sm ">
                                    <img src={smlogo} alt="Logo" width={40} height={38} />
                                </span>
                                <span className="logo-lg">
                                    <img src={lglogo} height={49} />
                                </span>
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 header-item "
                            id="vertical-menu-btn"
                        >
                            <i className="bx bx-bar-chart bx-rotate-90 bx-sm"></i>
                        </button>
                    </div>
                    <div className="d-flex">
                        {APP_CONFIG.IS_HORIZONTAL_LAYOUT_NOTIFIACTION && <NotificationDropdown />}
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </>
    );
};

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType
})(withTranslation()(Header));
