import React from "react";
import { useParams } from "react-router-dom";

import stateLogo from "../../assets/images/app/plate-talk/state-logo.svg";
import cityLogo from "../../assets/images/app/plate-talk/city-logo.svg";
import plateNoLogo from "../../assets/images/app/plate-talk/plate-no-logo.svg";
import manufactureLogo from "../../assets/images/app/plate-talk/make-logo.svg";
import modelLogo from "../../assets/images/app/plate-talk/model-logo.svg";
import ownerLogo from "../../assets/images/app/plate-talk/owner-logo.svg";
import locationLogo from "../../assets/images/app/plate-talk/location-logo.svg";
import yearLogo from "../../assets/images/app/plate-talk/year-logo.svg";
import calenderLogo from "../../assets/images/app/plate-talk/calendar-logo.svg";

import mailLogo from "../../assets/images/app/plate-talk/mail-logo.svg";
import phoneLogo from "../../assets/images/app/plate-talk/phone.svg";

import ImageGallery from "../../components/ImageGallery/ImageGallery";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { GetVehicleById } from "../../api/api.service";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { getMediaPath } from "../../utils/mediaUtils";
import LoaderFallbackUI from "../../components/LoaderFallbackUI/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";
import { SummaryCard } from "../../components/SummaryCard";
import { Col, Row } from "reactstrap";
import LabeledDataItem from "../../components/LabeledDataItem/LabeledDataItem";
import { monthYear } from "../../utils/dateUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";
import { DATA_LABELS } from "./Config";

const format = dateTimeFormat.appDateFormat;
const Details = () => {
    const { vehicleID } = useParams();
    let { useFetch } = useRequestProcessor();
    const { data, isLoading, refetch, isFetching } = useFetch(
        getKey([MODULE_KEYS.VEHICLE_MANAGEMENT_DETAILS], { vehicleID }),
        async () => await GetVehicleById(vehicleID)
    );

    if (isFetching || isLoading || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }
    return (
        <div className="my-4 px-4">
            <ImageGallery
                images={data?.Medias}
                containerClasses={`${
                    data?.Medias?.length < 2 && "slider-margin"
                } slider-container `}
                imageContainer="slider-image "
                sliderImage="image"
                loading={false}
            />

            <SummaryCard
                colClassName="mt-4"
                title={`${data?.Manufacturer} ${data?.Model} ${data?.Year}`}
                titleClassName="vehicle-detail-title "
            >
                <Row className="vehicle-detail-container">
                    <Col md={6}>
                        <LabeledDataItem
                            title={DATA_LABELS.PLATE_NO}
                            desc={data?.PlateNumber || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={plateNoLogo}
                            imgClass="img-class"
                            iconClass="icon-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />
                        <LabeledDataItem
                            title={DATA_LABELS.MAKE}
                            desc={data?.Manufacturer || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={manufactureLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />

                        <LabeledDataItem
                            title={DATA_LABELS.MODEL}
                            desc={data?.Model || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={modelLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />
                        <LabeledDataItem
                            title={DATA_LABELS.YEAR}
                            desc={data?.Year || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={yearLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledDataItem
                            title={DATA_LABELS.OWNER}
                            desc={data?.User?.FullName || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={ownerLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />
                        <LabeledDataItem
                            title={DATA_LABELS.REGISTERED_STATE}
                            desc={data?.State || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={locationLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />
                        <LabeledDataItem
                            title={DATA_LABELS.REGISTERED_CITY}
                            desc={data?.City || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={locationLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />

                        <LabeledDataItem
                            title={DATA_LABELS.DATE_CREATED}
                            desc={monthYear(format, data?.CreatedAt) || "N/A"}
                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                            menuImg={calenderLogo}
                            imgClass="img-class"
                            titleClass="titleClass"
                            descClass="desc-class"
                            rowClass="py-2"
                        />
                    </Col>
                </Row>
                {data?.LinkedUsers?.length > 0 && (
                    <Row className="vehicle-detail-container ">
                        {data?.LinkedUsers?.map((item, ind) => {
                            return (
                                <>
                                    <div className="linked-account-text">{`Linked Account-${
                                        ind + 1
                                    }`}</div>
                                    <Col md={6}>
                                        <LabeledDataItem
                                            title={DATA_LABELS.NAME}
                                            desc={item?.FullName || "N/A"}
                                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                                            menuImg={ownerLogo}
                                            imgClass="img-class"
                                            titleClass="titleClass"
                                            descClass="desc-class"
                                            rowClass="py-2"
                                        />
                                        <LabeledDataItem
                                            title={DATA_LABELS.MOBILE}
                                            desc={
                                                `${item?.CountryCode}${item?.PhoneNumber}` || "N/A"
                                            }
                                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                                            menuImg={phoneLogo}
                                            imgClass="img-class"
                                            titleClass="titleClass"
                                            descClass="desc-class"
                                            rowClass="py-2"
                                        />
                                        <LabeledDataItem
                                            title={DATA_LABELS.EMAIL}
                                            desc={item?.Email || "N/A"}
                                            titleColProps={{ xs: 5, md: 5, lg: 5 }}
                                            descColProps={{ xs: 7, md: 7, lg: 7 }}
                                            menuImg={mailLogo}
                                            imgClass="img-class"
                                            titleClass="titleClass"
                                            descClass="desc-class"
                                            rowClass="py-2"
                                        />
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                )}
            </SummaryCard>
        </div>
    );
};

export default Details;
