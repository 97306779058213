export const DATA_LABELS = {
    MOBILE: "Mobile ",
    EMAIL: "Email",
    PHONE: "Phone",
    DATE_JOINED: "Date Joined",
    STATE: "State",
    ADDRESS: "Address:",
    VEHICLES: "Vehicles:",
    CITY: "City"
};

export const filterOptions = [
    {
        label: "Active",
        value: "Active"
    },
    {
        label: "Block",
        value: "Suspended"
    }
];
