export const VEHICLES_COLUMNS = {
    S_NO: "SR. NO.",
    PLATE_NO: "Plate No.",
    LINKED: "Linked",
    VEHICLE_TYPE: "Vehicle Type",
    OWNER: "Owner",
    MAKE: "Make",
    MODEL: "Model",
    YEAR: "Year",
    STATUS: "Status",
    ACTION: "Action"
};

export const DATA_LABELS = {
    PLATE_NO: "Plate No.",
    MAKE: "Make",
    MODEL: "Model",
    YEAR: "Year",
    OWNER: "Owner",
    NAME: "Name",
    MOBILE: "Mobile ",
    EMAIL: "Email",
    PHONE: "Phone",
    REGISTERED_STATE: "Registered State",
    REGISTERED_CITY: "Registered City",
    DATE_CREATED: "Date Created",
    ADDRESS: "Address:",
    VEHICLES: "Vehicles:",
    CITY: "City"
};
