import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";

const InformationModal = ({
    isOpen = false,
    Title = "",
    confirmButtonText = "",
    ImageSrc = null,
    SubText = "",
    onConfirmClick = () => {},
    ...rest
}) => {
    return (
        <div>
            <CustomModal isOpen={isOpen} {...rest}>
                <div className="d-flex flex-column align-items-center">
                    {ImageSrc && <img src={ImageSrc} className="mb-2" />}

                    <div className="mb-4">
                        <h4 className=" fw-bold text-center custom-title">{Title}</h4>
                        {SubText && <h5 className="my-3  custom-subText">{SubText}</h5>}
                    </div>

                    <div className="d-flex justify-content-evenly w-75">
                        <CustomButton
                            title={confirmButtonText}
                            color="primary"
                            className="custom-btn-lg shadow-theme fw-bold grad-primary"
                            onClick={onConfirmClick}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default InformationModal;
