export default {
    LOGIN: "Login",
    FORGET_PASSWORD: "Forget Password",
    OTP_VERIFICATION: "OTP Verification",
    LOGOUT: "Logout",
    RESET_PASSWORD: "Reset Password",
    USER_MANAGEMENT: "User Management",
    VEHICLE_MANAGEMENT: "Vehicle Management",

    ACCOUNT: "Account"
};
