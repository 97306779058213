import { injectBaseConstantMethods } from "./BaseConstants";

export const SUBSCRIPTION_TYPE = {
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    ALWAYS: "Always",
    FREE: "Free"
};

const displayTextKeys = {
    [SUBSCRIPTION_TYPE.MONTHLY]: "Monthly",
    [SUBSCRIPTION_TYPE.YEARLY]: "Yearly",
    [SUBSCRIPTION_TYPE.ALWAYS]: "Always",
    [SUBSCRIPTION_TYPE.FREE]: "Free"
};

const labelClass = {
    [SUBSCRIPTION_TYPE.MONTHLY]: " cs-badge-vibrant-orange ",
    [SUBSCRIPTION_TYPE.YEARLY]: "cs-badge-vibrant-orange",
    [SUBSCRIPTION_TYPE.ALWAYS]: "cs-badge-forest-green",
    [SUBSCRIPTION_TYPE.FREE]: "cs-badge-forest-green"
};

export default injectBaseConstantMethods(SUBSCRIPTION_TYPE, displayTextKeys, labelClass);
