import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Row, Col, Breadcrumb as ReactstrapBreadcrumb, BreadcrumbItem } from "reactstrap";

import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import "./Breadcrumb.scss";

const Breadcrumb = ({ title, breadcrumbItems }) => {
    const matches = useRouteMatch();
    const { pathname } = useLocation();
    const pathNameArr = pathname?.split("/");

    const routeConfigs = [
        {
            path: APP_ROUTES.USER_MANAGEMENT,
            title: "USER MANAGEMENT",
            count: "1",
            matchUrl: "/users",
            key: "01"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.USER_MANAGEMENT_DETAILS, {
                userID: matches?.params?.userID
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/users/:userID",
            key: "02"
        },

        {
            path: APP_ROUTES.VEHICLE_MANAGEMENT,
            title: "VEHICLE MANAGEMENT",
            count: "1",
            matchUrl: "/vehicle-management",
            key: "03"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.VEHICLE_MANAGEMENT_DETAILS, {
                vehicleID: matches?.params?.vehicleID
            }),
            title: "VEHICLE DETAILS",
            count: "2",
            matchUrl: "/vehicle-management/:vehicleID",
            key: "04"
        },

        {
            path: APP_ROUTES.ACCOUNT_PROFILE,
            title: "PROFILE",
            count: "1",
            matchUrl: "/account/profile",
            key: "05"
        },
        {
            path: APP_ROUTES.ACCOUNT_CHANGE_PASSWORD,
            title: " CHANGE PASSWORD",
            count: "2",
            matchUrl: "/account/change-password",
            key: "06"
        }
    ];
    const breadcrumbItemsToRender = routeConfigs.filter((el) => pathname.startsWith(el.path));

    return (
        <Row className="breadcrumbs-con">
            <Col xs="12" className="breadcrumbs-col">
                <div className="d-flex align-items-center justify-content-between">
                    <ReactstrapBreadcrumb>
                        {breadcrumbItemsToRender.map((el, idx) => {
                            const isActive =
                                pathname === el.path || pathNameArr.includes(el.matchUrl);

                            return (
                                <BreadcrumbItem
                                    key={el.key}
                                    active={isActive}
                                    className={
                                        isActive
                                            ? "breadcrumb-typo-bold breadcrumb-max-width"
                                            : "breadcrumb-max-width breadcrumb-typo-bold "
                                    }
                                >
                                    {isActive || el.count === "2" ? (
                                        <span className="breadcrumb-typo-bold">{el.title} </span>
                                    ) : (
                                        <Link
                                            to={el.path}
                                            className="breadcrumb-typo breadcrumb-max-width"
                                        >
                                            {el.title}
                                        </Link>
                                    )}
                                </BreadcrumbItem>
                            );
                        })}
                    </ReactstrapBreadcrumb>
                </div>
            </Col>
        </Row>
    );
};

Breadcrumb.propTypes = {
    breadcrumbItems: PropTypes.array,
    title: PropTypes.string
};

export default Breadcrumb;
