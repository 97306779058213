import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

import { VEHICLE_DATA } from "./FilterData";
import AutoComplete from "../../components/AutoComplete/AutoComplete";
import { customStyles } from "../UserManagement/CustomStyles";
import { getManufacturerOptions, getModelOptions } from "../../utils/vehilceUtils";
import { generateYearOptions } from "../../utils/dateUtils";

const VehicleFilter = ({ onSelect }) => {
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    // Use useEffect to call the onSelect prop whenever any selection changes
    useEffect(() => {
        onSelect({
            vehicleType: selectedVehicleType,
            manufacturer: selectedManufacturer,
            model: selectedModel,
            year: selectedYear
        });
    }, [selectedVehicleType, selectedManufacturer, selectedModel, selectedYear]);

    const vehicleTypeOptions = VEHICLE_DATA.data.map((item) => ({
        value: item.vehicleType,
        label: item.vehicleType
    }));

    const manufacturerOptions = getManufacturerOptions(selectedVehicleType);
    const modelOptions = getModelOptions(selectedVehicleType, selectedManufacturer);
    const yearOptions = generateYearOptions(1990);

    const handleVehicleTypeChange = (selectedOption) => {
        setSelectedVehicleType(selectedOption);
        setSelectedManufacturer(null);
        setSelectedModel(null);
        setSelectedYear(null);
    };

    const handleManufacturerChange = (selectedOption) => {
        setSelectedManufacturer(selectedOption);
        setSelectedModel(null);
        setSelectedYear(null);
    };

    const handleModelChange = (selectedOption) => {
        setSelectedModel(selectedOption);
        setSelectedYear(null);
    };

    const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption);
    };

    return (
        <Row>
            <Col sm="12" md="1" lg="1"></Col>
            <Col sm="12" md="3" lg="3">
                <AutoComplete
                    customStyles={customStyles.statusFilter}
                    options={vehicleTypeOptions}
                    value={selectedVehicleType}
                    onChange={handleVehicleTypeChange}
                    isClearable
                    placeholder="Vehicle Type"
                    classNamePrefix="status-header-search-admin"
                    maxMenuHeight={220}
                />
            </Col>
            <Col sm="12" md="3" lg="3">
                <AutoComplete
                    customStyles={customStyles.statusFilter}
                    options={manufacturerOptions}
                    value={selectedManufacturer}
                    onChange={handleManufacturerChange}
                    placeholder="Make"
                    classNamePrefix="status-header-search-admin"
                    isDisabled={!selectedVehicleType}
                    isClearable
                    maxMenuHeight={220}
                />
            </Col>

            <Col sm="12" md="3" lg="3">
                <AutoComplete
                    customStyles={customStyles.statusFilter}
                    options={modelOptions}
                    value={selectedModel}
                    onChange={handleModelChange}
                    placeholder="Model"
                    classNamePrefix="status-header-search-admin"
                    isDisabled={!selectedManufacturer}
                    isClearable
                    maxMenuHeight={220}
                />
            </Col>

            <Col sm="12" md="2" lg="2">
                <AutoComplete
                    classNamePrefix="status-header-search-admin"
                    customStyles={customStyles.statusFilter}
                    options={yearOptions}
                    value={selectedYear}
                    onChange={handleYearChange}
                    placeholder="Year"
                    isDisabled={!selectedModel}
                    isClearable
                    maxMenuHeight={220}
                />
            </Col>
        </Row>
    );
};

export default VehicleFilter;
