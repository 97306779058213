import { UserStatus } from "../../constants";

import updateUserModalImg from "../../assets/images/app/plate-talk/update-user-logo.svg";

export const MODAL_CONTENT_CONFIG = {
    [UserStatus.SUSPENDED]: {
        modalTitle: "Update User",
        modalSubText: "Are you sure you want to block this user?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: updateUserModalImg
    },
    [UserStatus.VERIFIED || UserStatus.UNVERIFIED]: {
        modalTitle: "Update User",
        modalSubText: "Are you sure you want to activate this user?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: updateUserModalImg
    }
};
