import { fetchUtil } from "./fetchUtil";
import { store } from "../store";
import { MediaServerTypes } from "../constants/";
import { MediaUpload } from "../api/api.service";
import { removeSpaceUnderscoreCharacterFromString } from "./commonUtils";
import placeholder from "../assets/images/app/plate-talk/placeholder1.png";

let isConfigUpdated = false;
const KB_CONVERTER = 1024;
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const toBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result);
    };
    reader.onerror = function (error) {};
};

export const initializeMediaUtil = (file, isPublic = false) => {
    const token = store.getState().userAuth.user.Token;

    return fetchUtil({
        url: isPublic ? "/media/upload/public/init" : "/media/upload/init",
        body: JSON.stringify({
            Name: file.name,
            MimeType: file.type,
            Size: file.size
        }),
        method: "POST",
        ...(!isPublic && { token })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const uploadOnS3 = async (stream, credentials, cb) => {
    if (!window.AWS) {
        return;
    }
    if (!isConfigUpdated) {
        window.AWS.config.update({ region: credentials.Region });
        isConfigUpdated = true;
    }
    let s3 = new window.AWS.S3({
        credentials: new window.AWS.Credentials({
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretAccessKey,
            sessionToken: credentials.SessionToken,
            signatureVersion: "v4",
            region: credentials.Region
        })
    });

    let uploadedItem = await s3
        .upload({
            Bucket: credentials.Bucket,
            Key: credentials.Path,
            ACL: "public-read",
            Body: stream
        })
        .on("httpUploadProgress", function (progress) {
            cb(getUploadedProgress(progress.loaded, progress.total));
        })
        .promise();
    return uploadedItem;
};

export const getUploadedProgress = (uploadedSize, totalSize) => {
    let uploadedProgress = (uploadedSize / totalSize) * 100;
    return Number(uploadedProgress.toFixed(0));
};

export const getMediaStream = async (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.onerror = (err) => {
            reject(false);
        };
        reader.readAsArrayBuffer(file);
    });
};

export const finalizeMediaUtil = (Id, isPublic = false) => {
    const token = store.getState().userAuth.user.Token;

    return fetchUtil({
        url: isPublic ? "/media/upload/public/finalize" : "/media/upload/finalize",
        body: JSON.stringify({
            Id
        }),
        method: "POST",
        ...(!isPublic && { token })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getMediaPath = (media = null) => {
    if (media && media.BasePath && media.Path) {
        return `${media.BasePath}${media.Path}`;
    }
    // return "";
    return placeholder;
};

export const getImageFromMedia = (media) => {
    for (let i in media) {
        if (media[i].Type === MediaServerTypes.Image) {
            return media[i];
        }
    }
    return "";
};

export const getPreviewFile = (file) => {
    if (file && file.name) {
        return file && URL.createObjectURL(file);
    }
    return file;
};

export const isImageInvalid = (evt, sourceImage) => {
    evt.target.src = sourceImage;
};

export const imageUploadHandler = ({
    file,
    imagePreviewCB = () => {},
    imageErrorCB = () => {},
    uploadImgCB = () => {},
    sizeValidation = true,
    typeValidation = true,
    fileTypes = [],
    customErrorMessage = ""
}) => {
    const errorMessage = customErrorMessage
        ? customErrorMessage
        : "Please upload the image in either JPEG or PNG format.";
    if (typeValidation) {
        if (!validateImageType(file, fileTypes)) {
            imageErrorCB(errorMessage);
            imagePreviewCB(undefined);
            uploadImgCB("");
            return "typeError";
        }
    }
    if (sizeValidation) {
        if (!validateImageSize(file, 5, 0)) {
            imageErrorCB("Please make sure the image size should be less than 5MB.");
            imagePreviewCB(undefined);
            uploadImgCB("");
            return "sizeError";
        }
    }
    imagePreviewCB(URL.createObjectURL(file));
    let modifiedImageName = removeSpaceUnderscoreCharacterFromString(file?.name);
    let image = file;
    image.fileName = modifiedImageName;
    imageErrorCB(undefined);
    uploadImgCB(image);
};

export const validateImageType = (file, imageTypes) => {
    const uploadedFileType = file?.name
        ?.split(".")
        [file?.name?.split(".").length - 1]?.toLowerCase();
    return imageTypes.includes(uploadedFileType);
};

export const validateImageSize = (file, maxSize, minSize) => {
    let fileSize = file && file?.size;
    fileSize = fileSize / (KB_CONVERTER * KB_CONVERTER);
    return fileSize >= minSize && fileSize <= maxSize;
};

export const uploadMediaToServer = async (file, progressCB) => {
    try {
        progressCB(true);
        const formData = new FormData();
        formData.append("file", file);
        const { Id } = await MediaUpload(formData);
        return Number.parseInt(Id);
    } catch (err) {
        return;
    } finally {
        progressCB(false);
    }
};
