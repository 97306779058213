import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import viewIcon from "../../assets/images/app/common/listing-view-image.svg";

import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetVehicles } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles } from "../../constants";
import { SummaryCard } from "../../components/SummaryCard";
import { getMediaPath } from "../../utils/mediaUtils";
import { VEHICLES_COLUMNS } from "./Config";
import VehicleFilter from "./VehicleFilter";

const COLUMNS = {
    S_NO: "S.No",
    NAME: "Name",
    EMAIL: "Email",
    CONTACT_NUMBER: "Contact Number",
    CITY: "City",
    JOINED_ON: "Joined On",
    ACTION: "Action"
};

const VehicleManagement = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const [selectedValues, setSelectedValues] = useState({});

    const {
        isFetching,
        page,
        total,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.VEHICLE_MANAGEMENT]),
        dataKey: "Data",
        totalKey: "Count",
        requestFn: GetVehicles,
        params: {
            ...(selectedValues?.vehicleType && {
                Type: selectedValues?.vehicleType?.value
            }),
            ...(selectedValues?.manufacturer && {
                Manufacturer: selectedValues?.manufacturer?.value
            }),
            ...(selectedValues?.model && {
                Model: selectedValues?.model?.value
            }),
            ...(selectedValues?.year && {
                Year: selectedValues?.year?.value
            })
        }
    });

    const handleVehicleSelection = (values) => {
        setSelectedValues(values);
    };

    return (
        <div className="my-1">
            <Helmet>
                <title>{PageTitles.VEHICLE_MANAGEMENT}</title>
            </Helmet>
            <SummaryCard colClassName={"mt-2"}>
                <Row className="p-2 mt-2 ">
                    <Col sm="12" md="12" lg="3">
                        <SearchBox
                            className="me-3 w-100 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="12" lg="1"></Col>

                    <Col sm="12" md="12" lg="8" align="right">
                        <VehicleFilter onSelect={handleVehicleSelection} />
                    </Col>
                </Row>

                <DataTable
                    isHeightRequired={true}
                    tableContainerClass="mt-2"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: VEHICLES_COLUMNS.S_NO,
                            className: "px-4",
                            headerClass: "column-header",
                            render: (data) => <div>{data.Id || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.PLATE_NO,
                            headerClass: "column-header",
                            render: (data) => (
                                <div className="user">
                                    <div className="image">
                                        <img src={getMediaPath(data?.Medias[0])} alt="eye-icon" />
                                    </div>
                                    <div>{data?.PlateNumber}</div>
                                </div>
                            )
                        },
                        {
                            title: VEHICLES_COLUMNS.OWNER,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.User?.FullName || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.LINKED,
                            headerClass: "column-header",
                            render: (data) => (
                                <div>{data?.LinkedUsers?.length > 0 ? "Yes" : "No"}</div>
                            )
                        },
                        {
                            title: VEHICLES_COLUMNS.VEHICLE_TYPE,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.VehicleType || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.MAKE,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.Manufacturer || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.MODEL,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.Model || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.YEAR,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.Year || "-"}</div>
                        },

                        {
                            title: VEHICLES_COLUMNS.ACTION,
                            headerClass: "column-header",
                            render: (data) => {
                                return (
                                    <div className="d-flex">
                                        <img
                                            style={{
                                                backgroundColor: "#D7D7D",
                                                filter: " grayscale(100%)"
                                            }}
                                            src={viewIcon}
                                            role="button"
                                            alt={"details icon"}
                                            onClick={() => {
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        MODULE_KEYS.VEHICLE_MANAGEMENT_DETAILS
                                                    ]
                                                });
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.VEHICLE_MANAGEMENT_DETAILS,
                                                        {
                                                            vehicleID: data?.Id
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </SummaryCard>
            {/* <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-100 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6"></Col>
                    <Col sm="12" md="3" lg="3"></Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMNS.S_NO,
                            render: (data) => data?.id || "-"
                        },

                        {
                            title: COLUMNS.NAME,
                            render: (data) => truncateString(data.name, 30) || "-"
                        },

                        {
                            title: COLUMNS.EMAIL,
                            render: (data) => <div>{truncateString(data.email, 30)}</div> || "-"
                        },
                        {
                            title: COLUMNS.CONTACT_NUMBER,
                            render: (data) =>
                                (
                                    <div>
                                        {data?.countryCode}
                                        {data?.phone}
                                    </div>
                                ) || "-"
                        },
                        {
                            title: COLUMNS.CITY,
                            render: (data) => data?.location?.city || "-"
                        },

                        {
                            title: COLUMNS.JOINED_ON,
                            render: (data) =>
                                monthYear(dateTimeFormat.appDateFormat, data.createdAt) || "-"
                        },

                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex ">
                                        <img
                                            style={{
                                                filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                                            }}
                                            className="me-3"
                                            src={viewIcon}
                                            alt={"details icon"}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.VEHICLE_MANAGEMENT_DETAILS,
                                                        {
                                                            vehicleID: data?.id
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container> */}
        </div>
    );
};
export default VehicleManagement;
