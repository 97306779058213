import usersIcon from "../assets/images/app/plate-talk/sidebar-logos/users-logo.svg";
import vehicleIcon from "../assets/images/app/plate-talk/sidebar-logos/vehicle-mang.svg";
import SettingsIcon from "../assets/images/app/plate-talk/sidebar-logos/account.svg";

import { Roles } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";

let { Admin } = Roles;

const SIDEBAR_CONFIG = [
    {
        title: "User Management",
        url: APP_ROUTES.USER_MANAGEMENT,
        icon: null,
        menuImg: usersIcon,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Vehicle Management",
        url: APP_ROUTES.VEHICLE_MANAGEMENT,
        icon: null,
        menuImg: vehicleIcon,
        nestedMenu: null,
        permissions: [Admin]
    },

    {
        title: "Account",
        url: APP_ROUTES.ACCOUNT,
        icon: null,
        menuImg: SettingsIcon,
        nestedMenu: null,
        permissions: [Admin]
    }
];

export default SIDEBAR_CONFIG;
