export const APP_ROUTES = {
    // Authentication
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    FORGET_VERIFY_CODE: "/forget-verify-code/:tokenId",
    RESET_PASSWORD: "/reset-password/:tokenUUId",
    REGISTER: "/register",

    //Users Management
    USER_MANAGEMENT: "/users",
    USER_MANAGEMENT_DETAILS: "/users/:userID",

    // Vehicle Management
    VEHICLE_MANAGEMENT: "/vehicle-management",
    VEHICLE_MANAGEMENT_DETAILS: "/vehicle-management/:vehicleID",

    // Account
    ACCOUNT: "/account",
    ACCOUNT_PROFILE: "/account/profile",
    UPDATE_ACCOUNT_PROFILE: "/account/profile/update",
    ACCOUNT_CHANGE_PASSWORD: "/account/change-password"
};

export const getParamsAttachedRoute = (route, obj = {}) => {
    let keys = Object.keys(obj);

    if (keys?.length) {
        let objectKeys = keys;

        objectKeys.forEach(() => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
