export const MODULE_KEYS = {
    USER_MANAGEMENT: "USER_MANAGEMENT",
    USER_MANAGEMENT_DETAILS: "USER_MANAGEMENT_DETAILS",
    VEHICLE_MANAGEMENT: "VEHICLE_MANAGEMENT",
    VEHICLE_MANAGEMENT_DETAILS: "VEHICLE_MANAGEMENT_DETAILS",

    PROFILE_UPDATED: "PROFILE_UPDATED",
    ACCOUNT_CHANGE_PASSWORD: "ACCOUNT_CHANGE_PASSWORD"
};

export const getKey = (keys = [], params = {}) => {
    const KEYS = [...keys];

    const hasParams = Object.keys(params);

    if (hasParams.length) {
        KEYS.push(params);
    }

    return KEYS;
};
