import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";

import AuthWrapper from "./AuthWrapper";
import { CodeInput } from "../../components/CodeInput";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ForgotPasswordVerification, ForgotPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import { SUCCESS, showToast } from "../../utils/toastUtils";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds

const ForgetVerifyCode = ({}) => {
    const history = useHistory();
    const location = useLocation();
    const { tokenId } = useParams();
    const isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    const otpRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({ tokenUUId: "" });
    const [isResendCode, setIsResendCode] = useState(true);
    const [timer, setTimer] = useState(RESEND_INTERVAL);

    useEffect(() => {
        let interval;
        const savedTimer = localStorage.getItem("otpTimer");
        const lastTime = localStorage.getItem("lastTime");
        const timeElapsed = Math.floor((Date.now() - lastTime) / 1000);

        if (savedTimer && timeElapsed < RESEND_INTERVAL) {
            setTimer(Math.max(0, savedTimer - timeElapsed));
        }

        if (tokenId) {
            setToken(tokenId);
            setEmail(location?.state?.email || "");
        }

        if (isResendCode) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    const updatedTime = prev - 1;
                    localStorage.setItem("otpTimer", updatedTime);
                    localStorage.setItem("lastTime", Date.now());

                    if (updatedTime <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false);
                        localStorage.removeItem("otpTimer");
                        localStorage.removeItem("lastTime");
                        return RESEND_INTERVAL;
                    }
                    return updatedTime;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isResendCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const body = { Code: OTP, TokenUuid: token };
            const res = await ForgotPasswordVerification(body);
            const { TokenUuid } = res;
            setCodeAndToken({ tokenUUId: TokenUuid });

            history.push(
                getParamsAttachedRoute(APP_ROUTES.RESET_PASSWORD, { tokenUUId: TokenUuid })
            );
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            const res = await ForgotPassword({ Email: email });
            otpRef.current?.clear();
            showToast(SUCCESS, "An OTP has been sent to your email address!");
            history.replace({
                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                    tokenId: res?.verificationToken
                }),
                state: { email }
            });
        } finally {
            setOTP("");
        }
    };

    if (!location?.state?.email) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.OTP_VERIFICATION}</title>
            </Helmet>
            <AuthWrapper>
                <div className="bv-forget-wrap">
                    <div className="w-100 ">
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <p className="custom-text">{"One Time Password"}</p>
                        </div>
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <p className="m-0 p-0 custom-label text-center">
                                {"We have sent you the OTP at a**********@mail.com"}
                            </p>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-center p-4">
                        <form className="w-100" onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12}>
                                    <CodeInput
                                        className="my-4 text-center"
                                        onChange={(e) => setOTP(e)}
                                        ref={otpRef}
                                    />
                                </Col>
                            </Row>
                            <CustomButton
                                disabled={OTP.length !== OTP_LENGTH}
                                loading={isLoading}
                                onClick={handleSubmit}
                                color="primary"
                                type="submit"
                                title="Verify OTP"
                                className="w-100 mt-4 grad-primary"
                            />
                        </form>
                    </div>
                    <div className="d-flex align-items-center justify-content-center responsiveness mb-4">
                        <p className="font-clr-theme-secondary text-decoration-underline  text-white custom-auth-right-sub-text">
                            Trouble receiving OTP?
                        </p>{" "}
                        &nbsp;
                        <p
                            className={`font-clr-theme-secondary  text-white custom-auth-right-text ${
                                !isResendCode ? "cursor-pointer text-decoration-underline" : ""
                            }`}
                            onClick={!isResendCode ? handleResendCode : null}
                        >
                            {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend"}
                        </p>
                    </div>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ForgetVerifyCode);
