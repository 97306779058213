import { Details as VehicleDetails, VehicleManagement } from "../pages/VehicleManagement";

import { UserManagement, Detail as UserDetail } from "../pages/UserManagement";

import { Account, Profile as AdminProfile } from "../pages/Account";
import { APP_ROUTES } from "../helpers/routeHelpers";
import { Roles } from "../constants";

let { Admin } = Roles;

const authProtectedRoutes = [
    // User Management

    {
        path: APP_ROUTES.USER_MANAGEMENT,
        component: UserManagement,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.USER_MANAGEMENT_DETAILS,
        component: UserDetail,
        permissions: [Admin],
        exact: true
    },

    // Provider Management

    {
        path: APP_ROUTES.VEHICLE_MANAGEMENT,
        component: VehicleManagement,
        permissions: [Admin],
        exact: true
    },

    {
        path: APP_ROUTES.VEHICLE_MANAGEMENT_DETAILS,
        component: VehicleDetails,
        permissions: [Admin],
        exact: false
    },

    //Account

    {
        path: APP_ROUTES.ACCOUNT,
        component: Account,
        permissions: [Admin],
        exact: false
    }
];

export { authProtectedRoutes };
