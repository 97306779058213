import SkeletonEffect from "../SkeletonEffect/SkeletonEffect";

const ImageGallerySkeleton = () => {
    return (
        <>
            <div className="p-4 space-y-4">
                <div className="flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                        <SkeletonEffect classes=" rounded-lg h-20 w-20 " />
                        <SkeletonEffect classes=" rounded-lg h-20 w-20 " />
                        <SkeletonEffect classes=" rounded-lg h-20 w-20 " />
                        <SkeletonEffect classes=" rounded-lg h-20 w-20 " />
                    </div>
                    <SkeletonEffect classes=" rounded-lg h-100 w-full " />
                </div>
            </div>
        </>
    );
};

export default ImageGallerySkeleton;
