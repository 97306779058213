export let LABLES = {
    FULL_NAME: "Full Name",
    EMAIL: "Email",
    PASSWORD: "Current Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm New Password"
};

export let NAMES = {
    [LABLES.FULL_NAME]: "fullName",
    [LABLES.EMAIL]: "email",
    [LABLES.PASSWORD]: "oldPassword",
    [LABLES.NEW_PASSWORD]: "newPassword",
    [LABLES.CONFIRM_PASSWORD]: "confirmPassword"
};

export let PLACEHOLDERS = {
    [LABLES.FULL_NAME]: "Name",
    [LABLES.EMAIL]: "Email Address",
    [LABLES.PASSWORD]: "***********",
    [LABLES.NEW_PASSWORD]: "***********",
    [LABLES.CONFIRM_PASSWORD]: "***********"
};
