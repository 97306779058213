import { UserStatus } from "../../constants";

const UserActions = {
    VERIFIED: 1,
    SUSPENDED: 0
};

export const getStatus = (status) =>
    // status === UserStatus.VERIFIED ? UserStatus.UNVERIFIED : UserStatus.VERIFIED;
    status === UserStatus.VERIFIED || status === UserStatus.UNVERIFIED
        ? UserStatus.SUSPENDED
        : UserStatus.VERIFIED;

export const getStatusRunningOrCanceled = (status) =>
    // status === UserStatus.VERIFIED ? UserStatus.UNVERIFIED : UserStatus.VERIFIED;
    status === UserStatus.VERIFIED || status === UserStatus.UNVERIFIED
        ? UserStatus.SUSPENDED
        : UserStatus.VERIFIED;

export const findStatusChecked = (status) =>
    status === UserStatus.VERIFIED || status === UserStatus.UNVERIFIED
        ? UserActions.VERIFIED
        : UserActions.SUSPENDED;
export const handleAdModal = (setState, state) => setState(!state);
