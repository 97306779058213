import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter, useHistory, useParams, Redirect, Link } from "react-router-dom";
import { useFormik } from "formik";

import AuthWrapper from "./AuthWrapper";
import { PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ResetPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import { CustomModal } from "../../components/CustomModal";
import successImg from "../../assets/images/app/common/modal-success-img.svg";
import { initialValues, resetPassFormValidationSchema } from "./FormValidations";
import { SUCCESS, showToast } from "../../utils/toastUtils";
import { LABLES, NAMES, PLACEHOLDERS } from "./AuthFieldsNames";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

const ResetPasswordView = ({}) => {
    let { NEW_PASSWORD, CONFIRM_PASSWORD } = LABLES;
    const history = useHistory();
    const { tokenUUId } = useParams();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: initialValues.resetPasswordForm,
        validationSchema: resetPassFormValidationSchema,

        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        let { confirmPassword } = values;

        try {
            setIsLoading(true);
            let body = {
                TokenUuid: tokenUUId,
                Password: confirmPassword
            };
            let res = await ResetPassword(body);
            showToast(SUCCESS, "Password has been reset successfully !");
            history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN));
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.RESET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper>
                <div className="w-100">
                    <div className="w-100 ">
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <p className="custom-text">{"Create New Password"}</p>
                        </div>
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <p className="m-0 p-0 custom-label text-center">
                                {"Please update your password below and sign in again"}
                            </p>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-center px-4 pt-4 pb-3">
                        <form className="w-100" onSubmit={formik.handleSubmit}>
                            <div className="mb-2">
                                <PasswordInput
                                    placeholder={PLACEHOLDERS[NEW_PASSWORD]}
                                    label={NEW_PASSWORD}
                                    name={NAMES[NEW_PASSWORD]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[NEW_PASSWORD]]}
                                    labelClass="custom-label mb-0"
                                />

                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={NAMES[NEW_PASSWORD]}
                                    className="error"
                                />
                            </div>
                            <div className="mb-1">
                                <PasswordInput
                                    placeholder={PLACEHOLDERS[CONFIRM_PASSWORD]}
                                    label={CONFIRM_PASSWORD}
                                    name={NAMES[CONFIRM_PASSWORD]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[CONFIRM_PASSWORD]]}
                                    labelClass="custom-label mb-0"
                                />

                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={NAMES[CONFIRM_PASSWORD]}
                                    className="error"
                                />
                            </div>

                            <CustomButton
                                loading={isLoading}
                                color="primary"
                                type={"submit"}
                                title="Update Password"
                                className="w-100 mt-4 grad-primary"
                            />
                        </form>
                    </div>
                </div>
                <CustomModal isOpen={showResetSuccessModal} size="md">
                    <div className="text-center">
                        <img src={successImg} className="mb-4" />
                        <h4 className="font-clr-theme-secondary fw-bold">
                            Password Reset Successfully
                        </h4>
                        <p className="my-4 font-clr-theme-secondary">
                            Your password has been reset
                        </p>
                        <CustomButton
                            color="primary"
                            title="Back to Login"
                            className="custom-btn-sm"
                            onClick={() => history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN))}
                        />
                    </div>
                </CustomModal>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ResetPasswordView);
